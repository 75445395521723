import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeLayers,
  FontAwesomeLayersText,
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

import {
  faMicrosoft as freeFabFaMicrosoft
} from '@fortawesome/free-brands-svg-icons'

import {
  faMinus as proFasFaMinus,
  faPlus as proFasFaPlus,
  faMoon as proFasFaMoon,
  faGripVertical as proFasFaGripVertical,
  faBolt as proFasFaBolt,
  faScanner as proFasFaScanner,
  faMinusCircle as proFasFaMinusCircle,
  faPlusCircle as proFasFaPlusCircle,
  faThLarge as proFasFaThLarge,
  faCaretUp as proFasFaCaretUp,
  faCaretDown as proFasFaCaretDown,
  faCircle as proFasFaCircle
} from '@fortawesome/pro-solid-svg-icons'

import {
  faTimes as proFarFaTimes,
  faSortUp as proFarFaSortUp,
  faSortDown as proFarFaSortDown,
  faCheck as proFarFaCheck,
  faEllipsisH as proFarFaEllipsisH,
  faArrowRight as proFarFaArrowRight,
  faAngleLeft as proFarFaAngleLeft,
  faAngleRight as proFarFaAngleRight,
  faAngleDown as proFarFaAngleDown,
  faAngleUp as proFarFaAngleUp,
  faBold as proFarFaBold,
  faItalic as proFarFaItalic,
  faStrikethrough as proFarFaStrikethrough,
  faUnderline as proFarFaUnderline,
  faParagraph as proFarFaParagraph,
  faH1 as proFarFaH1,
  faH2 as proFarFaH2,
  faH3 as proFarFaH3,
  faListUl as proFarFaListUl,
  faListOl as proFarFaListOl,
  faUndo as proFarFaUndo,
  faRedo as proFarFaRedo,
  faLink as proFarFaLink,
  faMinus as proFarFaMinus,
  faArrowToLeft as proFarFaArrowToLeft,
  faArrowToRight as proFarFaArrowToRight,
  faArrowToTop as proFarFaArrowToTop,
  faArrowToBottom as proFarFaArrowToBottom,
  faTh as proFarFaTh,
  faPlus as proFarFaPlus,
  faCopyright as proFarFaCopyright,
  faSpinnerThird as proFarFaSpinnerThird,
  faCheckCircle as proFarFaCheckCircle,
  faClock as proFarFaClock,
  faCaretUp as proFarFaCaretUp,
  faCaretDown as proFarFaCaretDown,
  faInfoCircle as proFarFaInfoCircle,
  faUser as proFarFaUser,
  faShieldCheck as proFarFaShieldCheck,
  faAngleDoubleRight as proFarFaAngleDoubleRight,
  faHandPaper as proFarFaHandPaper,
  faBan as proFarFaBan,
  faBadgeCheck as proFarFaBadgeCheck,
  faSlidersV as proFarFaSlidersV,
  faFileDownload as proFarFaFileDownload,
  faPencil as proFarFaPencil,
  faExclamationCircle as proFarFaExclamationCircle,
  faFilter as proFarFaFilter,
  faInfoSquare as proFarFaInfoSquare,
  faCommentAltLines as proFarFaCommentAltLines,
  faStickyNote as proFarFaStickyNote,
  faStore as proFarFaStore,
  faBolt as proFarFaBolt,
  faVolumeMute as proFarFaVolumeMute,
  faVolumeUp as proFarFaVolumeUp,
  faClone as proFarFaClone,
  faWallet as proFarFaWallet,
  faPortalEnter as proFarFaPortalEnter,
  faPortalExit as proFarFaPortalExit,
  faCheckDouble as proFarFaCheckDouble,
  faUserClock as proFarFaUserClock,
  faExternalLinkAlt as proFarFaExternalLinkAlt,
  faSignOut as proFarFaSignOut,
  faPlusSquare as proFarFaPlusSquare,
  faMinusSquare as proFarFaMinusSquare,
  faScanner as proFarFaScanner,
  faCog as proFarFaCog,
  faSave as proFarFaSave,
  faReceipt as proFarFaReceipt,
  faMoneyBill as proFarFaMoneyBill,
  faTag as proFarFaTag,
  faSwatchbook as proFarFaSwatchbook,
  faEyeSlash as proFarFaEyeSlash,
  faLock as proFarFaLock
} from '@fortawesome/pro-regular-svg-icons'

import {
  faExternalLink as proFalFaExternalLink
} from '@fortawesome/pro-light-svg-icons'

import {
  faAngleDown as proFadFaAngleDown,
  faAngleUp as proFadFaAngleUp,
  faAngleRight as proFadFaAngleRight,
  faSpinnerThird as proFadFaSpinnerThird,
  faCalendar as proFadFaCalendar,
  faTimes as proFadFaTimes,
  faSearch as proFadFaSearch,
  faExclamationCircle as proFadFaExclamationCircle,
  faLock as proFadFaLock,
  faCheckCircle as proFadFaCheckCircle,
  faBan as proFadFaBan,
  faExternalLink as proFadFaExternalLink,
  faTimesCircle as proFadFaTimesCircle,
  faInfoCircle as proFadFaInfoCircle,
  faChartLine as proFadFaChartLine,
  faSwatchbook as proFadFaSwatchbook,
  faCalendarCheck as proFadFaCalendarCheck,
  faTags as proFadFaTags,
  faPencilAlt as proFadFaPencilAlt,
  faPencil as proFadFaPencil,
  faTrashAlt as proFadFaTrashAlt,
  faPauseCircle as proFadFaPauseCircle,
  faImage as proFadFaImage,
  faImages as proFadFaImages,
  faCheckSquare as proFadFaCheckSquare,
  faTimesSquare as proFadFaTimesSquare,
  faClone as proFadFaClone,
  faCalendarEdit as proFadFaCalendarEdit,
  faCalendarExclamation as proFadFaCalendarExclamation,
  faCalendarStar as proFadFaCalendarStar,
  faCalendarPlus as proFadFaCalendarPlus,
  faCalendarMinus as proFadFaCalendarMinus,
  faArrowsV as proFadFaArrowsV,
  faUsers as proFadFaUsers,
  faFileContract as proFadFaFileContract,
  faKeySkeleton as proFadFaKeySkeleton,
  faFileCsv as proFadFaFileCsv,
  faFileExcel as proFadFaFileExcel,
  faClipboard as proFadFaClipboard,
  faClipboardCheck as proFadFaClipboardCheck,
  faShoppingCart as proFadFaShoppingCart,
  faSortAmountUp as proFadFaSortAmountUp,
  faSortAmountDown as proFadFaSortAmountDown,
  faFileDownload as proFadFaFileDownload,
  faPaperPlane as proFadFaPaperPlane,
  faCheckDouble as proFadFaCheckDouble,
  faUndo as proFadFaUndo,
  faEye as proFadFaEye,
  faEyeSlash as proFadFaEyeSlash,
  faPalette as proFadFaPalette,
  faQrcode as proFadFaQrcode,
  faBarcodeRead as proFadFaBarcodeRead,
  faPortalEnter as proFadFaPortalEnter,
  faPortalExit as proFadFaPortalExit,
  faVolumeUp as proFadFaVolumeUp,
  faCamera as proFadFaCamera,
  faSignOut as proFadFaSignOut,
  faBookOpen as proFadFaBookOpen,
  faHistory as proFadFaHistory,
  faEnvelopeOpenText as proFadFaEnvelopeOpenText,
  faScanner as proFadFaScanner,
  faPowerOff as proFadFaPowerOff,
  faThLarge as proFadFaThLarge,
  faEnvelope as proFadFaEnvelope,
  faMugHot as proFadFaMugHot,
  faSpaceStationMoonAlt as proFadFaSpaceStationMoonAlt
} from '@fortawesome/pro-duotone-svg-icons'

library.add(
  freeFabFaMicrosoft,
  proFasFaMinus,
  proFasFaPlus,
  proFasFaMoon,
  proFasFaGripVertical,
  proFasFaBolt,
  proFasFaScanner,
  proFasFaMinusCircle,
  proFasFaPlusCircle,
  proFasFaThLarge,
  proFasFaCaretUp,
  proFasFaCaretDown,
  proFasFaCircle,
  proFarFaTimes,
  proFarFaSortUp,
  proFarFaSortDown,
  proFarFaCheck,
  proFarFaEllipsisH,
  proFarFaArrowRight,
  proFarFaAngleLeft,
  proFarFaAngleRight,
  proFarFaAngleDown,
  proFarFaAngleUp,
  proFarFaBold,
  proFarFaItalic,
  proFarFaStrikethrough,
  proFarFaUnderline,
  proFarFaParagraph,
  proFarFaH1,
  proFarFaH2,
  proFarFaH3,
  proFarFaListUl,
  proFarFaListOl,
  proFarFaUndo,
  proFarFaRedo,
  proFarFaLink,
  proFarFaMinus,
  proFarFaArrowToLeft,
  proFarFaArrowToRight,
  proFarFaArrowToTop,
  proFarFaArrowToBottom,
  proFarFaTh,
  proFarFaPlus,
  proFarFaCopyright,
  proFarFaSpinnerThird,
  proFarFaCheckCircle,
  proFarFaClock,
  proFarFaCaretUp,
  proFarFaCaretDown,
  proFarFaInfoCircle,
  proFarFaUser,
  proFarFaShieldCheck,
  proFarFaAngleDoubleRight,
  proFarFaHandPaper,
  proFarFaBan,
  proFarFaBadgeCheck,
  proFarFaSlidersV,
  proFarFaFileDownload,
  proFarFaPencil,
  proFarFaExclamationCircle,
  proFarFaFilter,
  proFarFaInfoSquare,
  proFarFaCommentAltLines,
  proFarFaStickyNote,
  proFarFaStore,
  proFarFaBolt,
  proFarFaVolumeMute,
  proFarFaVolumeUp,
  proFarFaClone,
  proFarFaWallet,
  proFarFaPortalEnter,
  proFarFaPortalExit,
  proFarFaCheckDouble,
  proFarFaUserClock,
  proFarFaExternalLinkAlt,
  proFarFaSignOut,
  proFarFaPlusSquare,
  proFarFaMinusSquare,
  proFarFaScanner,
  proFarFaCog,
  proFarFaSave,
  proFarFaReceipt,
  proFarFaMoneyBill,
  proFarFaTag,
  proFarFaSwatchbook,
  proFarFaEyeSlash,
  proFarFaLock,
  proFalFaExternalLink,
  proFadFaAngleDown,
  proFadFaAngleUp,
  proFadFaAngleRight,
  proFadFaSpinnerThird,
  proFadFaCalendar,
  proFadFaTimes,
  proFadFaSearch,
  proFadFaExclamationCircle,
  proFadFaLock,
  proFadFaCheckCircle,
  proFadFaBan,
  proFadFaExternalLink,
  proFadFaTimesCircle,
  proFadFaInfoCircle,
  proFadFaChartLine,
  proFadFaSwatchbook,
  proFadFaCalendarCheck,
  proFadFaTags,
  proFadFaPencilAlt,
  proFadFaPencil,
  proFadFaTrashAlt,
  proFadFaPauseCircle,
  proFadFaImage,
  proFadFaImages,
  proFadFaCheckSquare,
  proFadFaTimesSquare,
  proFadFaClone,
  proFadFaCalendarEdit,
  proFadFaCalendarExclamation,
  proFadFaCalendarStar,
  proFadFaCalendarPlus,
  proFadFaCalendarMinus,
  proFadFaArrowsV,
  proFadFaUsers,
  proFadFaFileContract,
  proFadFaKeySkeleton,
  proFadFaFileCsv,
  proFadFaFileExcel,
  proFadFaClipboard,
  proFadFaClipboardCheck,
  proFadFaShoppingCart,
  proFadFaSortAmountUp,
  proFadFaSortAmountDown,
  proFadFaFileDownload,
  proFadFaPaperPlane,
  proFadFaCheckDouble,
  proFadFaUndo,
  proFadFaEye,
  proFadFaEyeSlash,
  proFadFaPalette,
  proFadFaQrcode,
  proFadFaBarcodeRead,
  proFadFaPortalEnter,
  proFadFaPortalExit,
  proFadFaVolumeUp,
  proFadFaCamera,
  proFadFaSignOut,
  proFadFaBookOpen,
  proFadFaHistory,
  proFadFaEnvelopeOpenText,
  proFadFaScanner,
  proFadFaPowerOff,
  proFadFaThLarge,
  proFadFaEnvelope,
  proFadFaMugHot,
  proFadFaSpaceStationMoonAlt
)

config.autoAddCss = false

Vue.component('fa', FontAwesomeIcon)
Vue.component('faLayers', FontAwesomeLayers)
Vue.component('faLayersText', FontAwesomeLayersText)
