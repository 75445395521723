import { Attr } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'

export class Image extends ApiResource {
  static jsonApiType = 'images'
  @Attr() url: string
  @Attr() copyright: string | null
  @Attr() width: number
  @Attr() height: number
  @Attr() preview_image_base64: string | null
  @Attr() previewImageBase64: string | null
  @Attr() smallPath: string
  @Attr() small_path: string
  @Attr() medium_path: string
  @Attr() mediumPath: string
  @Attr() large_path: string
  @Attr() largePath: string
  @Attr() icons?: { [key: string]: string }
  @Attr() orderIndex: number
  @Attr() avatars: {
    '180x180'?: string
    '360x360'?: string
  }
}
