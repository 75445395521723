import {
  Attr,
  BelongsTo,
  HasMany,
  JsonApiService,
} from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { Resource } from '@/shared/jsonapi-orm/bookingbuddy/Resource'
import { Organization } from '@/shared/jsonapi-orm/bookingbuddy/Organization'
import { DisplayType } from '@/shared/jsonapi-orm/displays/DisplayType'
import { QueryBuilder } from '@anny.co/vue-jsonapi-orm/dist/builder/QueryBuilder'

export class Display extends ApiResource {
  static jsonApiType = 'displays'
  @Attr() macAddress: string
  @Attr() name: string | null
  @Attr() width: number
  @Attr() height: number
  @Attr() batteryLevel: number
  @Attr() temperature: number | null
  @Attr() locale: string
  @Attr() timezone: string
  @Attr() config: {
    [key: string]: any
    wifi_rssi?: string
    wifi_ssid?: string
    temperature?: number
  } | null
  @Attr() anonymize: boolean
  @Attr() layout: string
  @Attr() lastSyncedAt: string | null

  @HasMany() resources?: Resource[]
  @BelongsTo() owner?: Organization | null
  @BelongsTo() displayType?: DisplayType | null

  /**
   * Unpair display from owner.
   */
  async unpair() {
    try {
      this.$isLoading = true
      await this.api().request('unpair')
      return
    } finally {
      this.$isLoading = false
    }
  }

  /**
   * Pair new display with code.
   * @param code
   * @param apiService
   */
  static async pair(
    code: string,
    apiService: JsonApiService
  ): Promise<Display> {
    const builder = new QueryBuilder(
      Display.apiPath,
      apiService.getClient(this),
      apiService
    )
    builder.include(['displayType', 'resources'])
    const { data } = await builder.request(
      Display.jsonApiType + '/pair',
      'POST',
      {
        data: { code },
      }
    )
    return Display.resourceFromResponse(data, apiService).data
  }

  /**
   * Calculate wifi quality from RSSI dBm to strength
   * props to https://stackoverflow.com/a/31852591
   */
  get wifiStrength(): number {
    const wifi_dBm = this.config?.wifi_rssi ? Number(this.config?.wifi_rssi) : 0
    return Math.min(Math.max(2 * (wifi_dBm + 100), 0), 100)
  }

  get wifiSsid(): string | null {
    return this.config?.wifi_ssid ?? null
  }

  /**
   * Battery Level rounded to 5
   */
  get batteryLevelRough(): number {
    return Math.ceil(this.batteryLevel / 5) * 5
  }
}
