import { reactive } from 'vue'
import { AxiosInstance } from 'axios'

/**
 * Cache loaded images
 * @type {Set<string>}
 */
const loadedImages = new Set()

/**
 * Load image in new img tag
 * Remove img tag and use image somewhere else from browser cache
 * @return Promise
 */
export const loadImage = (url: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (!document) reject()
    const img = document.createElement('img')
    // img.crossOrigin = ""
    img.onload = function () {
      loadedImages.add(url)
      img.remove()
      resolve()
    }
    img.onerror = function () {
      img.remove()
      reject()
    }
    img.onabort = function () {
      img.remove()
      reject()
    }
    img.src = url
  })
}

/**
 * Check of given image was loaded
 * @param url
 * @return {boolean}
 */
export const imageLoaded = (url: string): boolean => {
  return loadedImages.has(url)
}

/**
 * Get file as data url
 *
 * @param file {File}
 */
export function getFileDataUrl(file: File): Promise<string> {
  // return file path as promise
  return new Promise((resolve, reject) => {
    // read file
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = function () {
      resolve(reader.result as string)
    }
    reader.onabort = reject
    reader.onerror = reject
  })
}

/**
 * Create FormData object from files
 *
 * @param files
 * @param multiple
 */
export function makeFormDataFromFiles(
  files: File[],
  multiple = true
): FormData {
  const formData = new FormData()

  if (multiple) {
    for (let i = 0; i < files.length; i++) {
      formData.append('file[]', files[i]!)
    }
  } else {
    if (files.length > 0) {
      formData.append('file', files[0])
    }
  }

  return formData
}

export interface UploadingFileState {
  isUploading: boolean
  isUploaded: boolean
  uploadFailed: boolean
  uploadProgress: number
}

/**
 *
 * @param client
 */
export function useFileUploader() {
  const state = reactive<UploadingFileState>({
    isUploading: false,
    isUploaded: false,
    uploadFailed: false,
    uploadProgress: 0,
  })

  const upload = async (
    formData: FormData,
    url: string,
    client: AxiosInstance
  ) => {
    state.isUploading = true
    state.isUploaded = false
    state.uploadFailed = false

    try {
      const response = await client.post(url, formData, {
        onUploadProgress: function (progressEvent) {
          // upload completed = 70% progress
          const uploadPercentage = 70
          const percentCompleted = Math.round(
            (((progressEvent.loaded * 100) / progressEvent.total) *
              uploadPercentage) /
              100
          )
          state.uploadProgress = Math.min(uploadPercentage, percentCompleted)
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        timeout: 60 * 1000, // one minute timeout
      })

      // finish upload
      state.uploadProgress = 100

      if (window) {
        window.setTimeout(() => {
          state.isUploaded = true
          state.isUploading = false
          state.uploadFailed = false
          state.uploadProgress = 0
        }, 1000)
      }

      return response
    } catch (e) {
      state.uploadFailed = true
      state.isUploaded = false
      state.isUploading = false
    }
  }

  return {
    state,
    upload,
  }
}
