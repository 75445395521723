/**
 * Replace all occurrences in string
 *
 * @param str {string}
 * @param find {string}
 * @param replace {string}
 * @return {string}
 */
export function replaceAll(str, find, replace) {
  return str.replace(new RegExp(find, 'g'), replace)
}

export function lcFirst(str) {
  if (typeof str === 'string' && str.length > 0) {
    return str.charAt(0).toLowerCase() + str.slice(1)
  }
  return str
}

export function ucFirst(str) {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }
  return str
}

/**
 * Remove leading slashes from a given string
 * @param  {String} str
 * @return {String}
 */
export function removeLeadingSlash(str) {
  if (!str) {
    return ''
  }
  return str.replace(/^\/+/, '')
}

export function camelToText(str) {
  const result = str.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}
