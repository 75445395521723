import { Attr } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '../ApiResource'

export type ServiceGroupSettings = {
  maxBookings: {
    perDay: null | number
    perWeek: null | number
    perMonth: null | number
    perSlot: null | number
  }
}

export class ServiceGroup extends ApiResource {
  static jsonApiType = 'service-groups'
  @Attr() name: string
  @Attr({}) localNameI18n?: Record<string, string> | null
  @Attr() settings: Record<string, any>
}
