import { Attr, BelongsTo } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { CustomForm } from './CustomForm'

export enum CustomFieldType {
  TEXT = 'text',
  TEXTAREA = 'textarea',
  SELECT = 'select',
  MULTI_SELECT = 'multi-select',
  NUMBER = 'number',
  CHECKBOX = 'checkbox',
  FILE = 'file',
  DATE = 'date',
  DATETIME = 'datetime',
}

export class CustomField extends ApiResource {
  static jsonApiType = 'custom-fields'
  @Attr('') name: string
  @Attr('') description: string | null
  @Attr(CustomFieldType.TEXT) fieldType: CustomFieldType
  @Attr() field_type: CustomFieldType
  @Attr(false) required: boolean
  @Attr(false) displayInCheckout: boolean
  @Attr([]) options: string[]
  @Attr(null) defaultValue: string | null
  @Attr() default_value: string | null
  @Attr(0) orderIndex: number
  @BelongsTo() model: ApiResource | CustomForm

  get isOptionField(): boolean {
    return [CustomFieldType.MULTI_SELECT, CustomFieldType.SELECT].includes(
      this.fieldType
    )
  }
}
