
import { MetaInfo } from 'vue-meta'
import Vue from 'vue'

export default Vue.extend({
  middleware: ['panelSecret', 'auth'],
  head(): MetaInfo {
    let links: MetaInfo['link'] = [
      {
        rel: 'apple-touch-startup-image',
        hid: 'apple-touch-startup-image',
        type: 'image/png',
        href: '_favicons/apple-touch-icon.png',
        sizes: 'any',
      },
      {
        rel: 'apple-touch-icon',
        hid: 'apple-touch-icon',
        type: 'image/png',
        href: '_favicons/apple-touch-icon.png',
        sizes: 'any',
      },
      {
        rel: 'shortcut icon',
        href: '_favicons/favicon.ico',
        hid: 'shortcut icon',
      },
      {
        rel: 'icon',
        type: 'image/png',
        href: '_favicons/favicon-16x16.png',
        sizes: '16x16',
      },
      {
        rel: 'icon',
        type: 'image/png',
        href: '_favicons/favicon-32x32.png',
        sizes: '32x32',
      },
    ]
    return {
      titleTemplate: '%s | anny Admin',
      link: links,
    }
  },
  mounted() {
    this.$store.dispatch('ux/startReactiveTime')
  },
})
