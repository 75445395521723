import { initJsonApi, JsonApiService } from '@anny.co/vue-jsonapi-orm'
import { Context, Plugin } from '@nuxt/types'
import { User } from '@/shared/jsonapi-orm/bookingbuddy/User'
import { Ability } from '@/shared/jsonapi-orm/auth/Ability'
import { Role } from '@/shared/jsonapi-orm/auth/Role'
import { OrganizationUser } from '@/shared/jsonapi-orm/bookingbuddy/OrganizationUser'
import { Resource } from '@/shared/jsonapi-orm/bookingbuddy/Resource'
import { Service } from '@/shared/jsonapi-orm/bookingbuddy/Service'
import { CustomField } from '@/shared/jsonapi-orm/custom-fields/CustomField'
import { AddOn } from '@/shared/jsonapi-orm/bookingbuddy/AddOn'
import { Booking } from '@/shared/jsonapi-orm/bookingbuddy/Booking'
import { BookingAddOn } from '@/shared/jsonapi-orm/bookingbuddy/BookingAddOn'
import { Customer } from '@/shared/jsonapi-orm/bookingbuddy/Customer'
import { Organization } from '@/shared/jsonapi-orm/bookingbuddy/Organization'
import { Property } from '@/shared/jsonapi-orm/bookingbuddy/Property'
import { ResourceCategory } from '@/shared/jsonapi-orm/bookingbuddy/ResourceCategory'
import { ResourceGroup } from '@/shared/jsonapi-orm/bookingbuddy/ResourceGroup'
import { ResourceProperty } from '@/shared/jsonapi-orm/bookingbuddy/ResourceProperty'
import { Schedule } from '@/shared/jsonapi-orm/bookingbuddy/Schedule'
import { Display } from '@/shared/jsonapi-orm/displays/Display'
import { DisplayPanel } from '@/shared/jsonapi-orm/bookingbuddy/DisplayPanel'
import { Image } from '@/shared/jsonapi-orm/common/Image'
import { Industry } from '@/shared/jsonapi-orm/bookingbuddy/Industry'
import { Queue } from '@/shared/jsonapi-orm/queues/Queue'
import { QueueAgent } from '@/shared/jsonapi-orm/queues/QueueAgent'
import { QueueCounter } from '@/shared/jsonapi-orm/queues/QueueCounter'
import { QueueRule } from '@/shared/jsonapi-orm/queues/QueueRule'
import { QueueTicket } from '@/shared/jsonapi-orm/queues/QueueTicket'
import { Order } from '@/shared/jsonapi-orm/bookingbuddy/Order'
import { ServiceGroup } from '@/shared/jsonapi-orm/bookingbuddy/ServiceGroup'

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $jsonApiService: JsonApiService
  }
  interface Context {
    $jsonApiService: JsonApiService
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $jsonApiService: JsonApiService
  }
}

const plugin: Plugin = function (context: Context, inject) {
  const resources = [
    User,
    Ability,
    Role,
    OrganizationUser,
    Resource,
    Service,
    CustomField,
    AddOn,
    Booking,
    Order,
    BookingAddOn,
    Customer,
    Organization,
    Property,
    ResourceCategory,
    ResourceGroup,
    ResourceProperty,
    Schedule,
    Display,
    DisplayPanel,
    Image,
    Industry,
    Queue,
    QueueAgent,
    QueueCounter,
    QueueRule,
    QueueTicket,
    ServiceGroup,
  ]

  const jsonApiService = initJsonApi(
    {
      store: context.store,
      resources,
    },
    process.client
  )

  jsonApiService.useClient(context.$clients.authJsonApi)

  inject('jsonApiService', jsonApiService)
}

export default plugin
