import { Attr, BelongsTo, JsonApiService, Meta } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { Organization } from '@/shared/jsonapi-orm/bookingbuddy/Organization'
import { Image } from '@/shared/jsonapi-orm/common/Image'
import { QueryBuilder } from '@anny.co/vue-jsonapi-orm/dist/builder/QueryBuilder'

export interface PanelWidget {
  id: string
  resourceId: string
  orderIndex: number
  highlighted: boolean
  allowAdhocBooking?: boolean
  showOrganizer?: boolean
  allowGuestBooking?: boolean
}

export interface QueuePanelWidget {
  id: string
  queueId: string
  orderIndex: number
}

export interface Marquee {
  text: string
  speed: number
}

export enum PanelLayout {
  GRID = 'grid',
  ROWS = 'rows',
}
export enum PanelType {
  DEFAULT = 'default',
  APP = 'app',
  QUEUES = 'queues',
}

export interface AppDeviceInfo {
  id: string
  os: 'iOS' | 'Android'
  name: string
  model: string
  batteryLavel: number
  manufacturer:
    | 'Apple Inc'
    | 'OnePlus'
    | 'Google'
    | 'samsung'
    | 'motorola'
    | 'unknown'
}

export class DisplayPanel extends ApiResource {
  static jsonApiType = 'display-panels'

  @Attr() name: string | null
  @Attr() description: string | null
  @Attr(PanelType.DEFAULT) panelType: PanelType
  @Attr(PanelLayout.ROWS) layout: PanelLayout
  @Attr([]) widgets: (PanelWidget | QueuePanelWidget)[]
  @Attr(null) secondaryWidgets: PanelWidget[] | null
  @Attr() marquee: Marquee
  @Attr(5) borderRadius: number
  @Attr(30000) paginationDelay: number
  @Attr(null) backgroundColor: string | null
  @Attr(null) textColor: string | null
  @Attr(null) accentColor: string | null
  @Attr(null) secondaryColor: string | null
  @Attr(null) containerColor: string | null
  @Attr(false) anonymize: boolean
  @Attr(true) showBookMe: boolean
  @Attr() personalAccessToken?: string | null
  @Attr() createdAt: string | null
  @Attr() timezone: string
  @Attr() hasTouch: boolean
  @Attr() hasCamera: boolean
  @Attr() showQrCodes: boolean
  @Attr('1,2,3,4,5') activeDays: string
  @Attr('09:00:00') activeFrom: string
  @Attr('18:00:00') activeTo: string
  @Attr(10) inactiveBrightness: number
  @Attr(4) maxWidgetsPerPage: number
  @Attr() deviceInfo: AppDeviceInfo | null

  @BelongsTo() image?: Image | null

  @Meta() publicUrl?: string
  @BelongsTo() organization?: Organization

  async refreshToken() {
    await this.api().request('refresh-token')
  }

  static async pair(
    code: string,
    apiService: JsonApiService
  ): Promise<DisplayPanel> {
    const builder = new QueryBuilder(
      DisplayPanel.apiPath,
      apiService.getClient(this),
      apiService
    )
    builder.include([])
    const { data } = await builder.request(
      DisplayPanel.jsonApiType + '/pair',
      'POST',
      {
        data: { code },
      }
    )
    return DisplayPanel.resourceFromResponse(data, apiService).data
  }
}
