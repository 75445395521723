import {
  AuthStoragePaths,
  authStoragePaths,
  JWTToken,
  requestRefreshToken,
  revokeToken,
  TokenData,
} from '@/shared/utils/authHelpers'
import {
  createDefaultClient,
  getCognitorApiBaseUrl,
} from '@/shared/services/axiosClientFactory'
import { Context, Plugin } from '@nuxt/types'
import jwtDecode from 'jwt-decode'
import dayjs from 'dayjs'
import { GetterTree } from 'vuex'
import { AuthState } from '@/shared/store/authStore'
import { Organization } from '@/shared/jsonapi-orm/bookingbuddy/Organization'
import { User } from '@/shared/jsonapi-orm/bookingbuddy/User'
import { User as CognitorUser } from '@/shared/jsonapi-orm/cognitor/User'
import { CustomerAccount } from '@/shared/jsonapi-orm/bookingbuddy/CustomerAccount'
import { Inject } from '@nuxt/types/app'
import Vue from 'vue'
import { UrlBuilder } from '@/shared/types/UrlBuilder'
import mitt, { Emitter } from 'mitt'

export type SetTokenOptions = {
  accessToken: string
  refreshToken?: string
  expiresIn?: number
  expiresAt?: string
  saveAccessToken?: boolean
}

declare module 'vue/types/vue' {
  interface Vue {
    $authService: AuthService
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $authService: AuthService
  }
  interface Context {
    $authService: AuthService
  }
}

export type CustomerAccountWatcher = (
  oldAccount: CustomerAccount | null,
  newAccount: CustomerAccount | null
) => Promise<void>

export type AuthEvents = {
  TokenRefreshed: any
}

export class AuthService {
  protected state: AuthState

  protected _accountWatchers: CustomerAccountWatcher[] = []

  public account: CustomerAccount | null
  public _user: User | null
  public _organization: Organization | null
  public _cognitorUser: CognitorUser | null

  protected refreshTokenPromise: Promise<TokenData> | null = null
  protected isRequestingToken = false

  public mitt: Emitter<AuthEvents>

  redirectedToLogin = false

  constructor(protected context: Context, protected storagePrefix: string) {
    this.state = context.store.state.auth
    this._user = null
    this._organization = null
    this.account = null
    this.mitt = mitt<AuthEvents>()
  }

  get user(): User {
    return this._user!
  }

  set user(user: User) {
    const oldUser = this._user
    this._user = user
    this.userId = user.id
    oldUser?.$destruct()
  }

  get organization(): Organization {
    return this._organization!
  }
  set organization(organization: Organization) {
    const oldOrganization = this._organization
    this._organization = organization
    this.organizationId = organization.id
    oldOrganization?.$destruct()
  }

  get cognitorUser(): CognitorUser {
    return this._cognitorUser!
  }

  // getter for storage paths
  get storagePaths(): AuthStoragePaths {
    return authStoragePaths(this.storagePrefix)
  }

  get authState(): AuthState {
    return this.context.store.state.auth
  }

  get authGetters(): GetterTree<AuthState, any> {
    return this.context.store.getters['auth']
  }

  get jwtToken(): JWTToken | null {
    return this.authState.jwtToken
  }

  set jwtToken(value: JWTToken | null) {
    this.context.store.commit('auth/setJwtToken', value)
  }

  get hasAccessToken(): boolean {
    return !!this.accessToken
  }

  get accessToken(): string | null {
    return this.authState.accessToken
  }

  set accessToken(value: string | null) {
    this.context.store.commit('auth/setAccessToken', value)
  }

  get hasRefreshToken(): boolean {
    return !!this.refreshToken
  }

  get refreshToken(): string | null {
    return this.authState.refreshToken
  }

  set refreshToken(value: string | null) {
    this.context.store.commit('auth/setRefreshToken', value)
  }

  get expiresIn(): number | null {
    return this.authState.expiresIn
  }

  set expiresIn(value: number | null) {
    this.context.store.commit('auth/setExpiresIn', value)
  }

  get expiresAt(): string | null {
    return this.authState.expiresAt
  }

  set expiresAt(value: string | null) {
    this.context.store.commit('auth/setExpiresAt', value)
  }

  get customerAccountId(): string | null {
    return this.authState.customerAccountId
  }

  set customerAccountId(value: string | null) {
    this.context.store.commit('auth/setCustomerAccountId', value)
  }

  get userId(): string | null {
    return this.authState.userId
  }

  set userId(value: string | null) {
    this.context.store.commit('auth/setUserId', value)
  }

  get organizationId(): string | null {
    return this.authState.organizationId
  }

  set organizationId(value: string | null) {
    this.context.store.commit('auth/setOrganizationId', value)
  }

  get cognitorUserId(): string | null {
    return this.authState.cognitorUserId
  }

  set cognitorUserId(value: string | null) {
    this.context.store.commit('auth/setCognitorUserId', value)
  }

  updateRefreshTokenFromStorage() {
    if (process.client && 'localStorage' in window) {
      const token = window.localStorage.getItem(this.storagePaths.refreshToken)
      if (token) {
        this.refreshToken = token
      }
    }
  }

  /**
   * Check if token is expired and refresh it
   * else just return token
   */
  async getAccessToken(forceRefresh = false): Promise<string | null> {
    // to return an access token we need at least on token
    if (!this.accessToken && !this.refreshToken) {
      return null
    }

    // Check if the token is expired and refresh it
    if (forceRefresh || (this.accessTokenExpired() && this.hasRefreshToken)) {
      try {
        await this.refreshAccessToken()
      } catch (e) {
        // login if in client environment
        if (process.client) {
          this.redirectToLogin()
          return null
        } else {
          throw e
        }
      }
    }

    return this.accessToken
  }

  /**
   * check if the access token is expired.
   */
  public accessTokenExpired(): boolean {
    if (!this.accessToken && this.refreshToken) {
      return true
    }

    if (this.expiresAt) {
      const now = dayjs()
      const expiry = dayjs(this.expiresAt)
      return now.isAfter(expiry)
    } else {
      return true
    }
  }

  updateTokens({
    accessToken,
    refreshToken,
    expiresIn,
    expiresAt,
    saveAccessToken = true,
  }: SetTokenOptions) {
    if (!accessToken && !refreshToken) {
      return
    }
    // in outlook context we don't have access to the cookies
    // they will be stored in the roaming settings
    const setCookies = this.context.$config.setAuthCookies
    // Put to state
    this.accessToken = accessToken
    this.refreshToken = refreshToken ?? null
    this.expiresIn = expiresIn ?? null
    this.expiresAt = expiresAt ?? null

    // Decode tokens
    if (accessToken) {
      this.jwtToken = jwtDecode(accessToken)
    }

    if (saveAccessToken && this.context.$embedService) {
      this.context.$embedService?.persistData(
        this.storagePaths.jwtToken,
        accessToken
      )
    }

    if (
      saveAccessToken &&
      typeof this.context.$cookies !== 'undefined' &&
      setCookies
    ) {
      if (accessToken) {
        // Save access token to cookies
        this.context.$cookies.set(this.storagePaths.jwtToken, accessToken, {
          path: '/',
          secure: this.context.$config.useSecureCookies,
          expires: dayjs.unix(this.jwtToken!.exp).toDate(),
          sameSite: 'none',
        })
      }
      if (refreshToken) {
        // store in cookies for ssr access
        this.context.$cookies.set(
          this.storagePaths.refreshToken,
          refreshToken,
          {
            path: '/',
            secure: this.context.$config.useSecureCookies,
            expires: dayjs().add(7, 'days').toDate(),
            sameSite: 'none',
          }
        )

        // store in local storage for multi-tab-sync
        if (process.client && 'localStorage' in window) {
          window.localStorage.setItem(
            this.storagePaths.refreshToken,
            refreshToken
          )
        }
      }
    }
  }

  async logout(postLogoutUrl: string | null = null): Promise<string> {
    if (this.accessToken) {
      // Revoke token
      const endpoint =
        this.context.$config.cognitorBaseUri +
        this.context.$config.cognitorTokensApiPath

      try {
        await revokeToken({
          axiosClient: createDefaultClient(this.context, '', endpoint),
          token: this.accessToken,
          endpoint,
        })
      } catch (e) {
        // fail silently
      }
    }

    // remove from store and cookies
    this.removeTokens()

    if (!postLogoutUrl) {
      postLogoutUrl = this.context.query.target as string
    }

    const urlBuilder = new UrlBuilder({
      baseUrl: this.context.$config.cognitorBaseUri,
      path: this.context.$config.cognitorLogoutPath,
      queryParams: {
        post_logout_redirect_uri: postLogoutUrl,
        locale: this.context.app.i18n.locale,
      },
    })

    return urlBuilder.url
  }

  removeTokens() {
    // remove from cookies
    if (typeof this.context.$cookies !== 'undefined') {
      this.context.$cookies.remove(this.storagePaths.jwtToken, {
        path: '/',
        secure: this.context.$config.useSecureCookies,
        sameSite: 'none',
      })
      this.context.$cookies.remove(this.storagePaths.refreshToken, {
        path: '/',
        secure: this.context.$config.useSecureCookies,
        sameSite: 'none',
      })
    }
    if (process.client && 'localStorage' in window) {
      window.localStorage.removeItem(this.storagePaths.refreshToken)
    }

    // remove from state
    this.accessToken = null
    this.refreshToken = null
    this.expiresAt = null
    this.expiresIn = null
  }

  /**
   * Refreshes the access token. You can provide the scope and
   * decide if the access token should be stored in cookies
   * for future use.
   *
   * @param scope
   * @param saveAccessToken
   */
  async refreshAccessToken(scope = '', saveAccessToken = true) {
    // return ongoing request if available
    if (this.refreshTokenPromise) {
      return this.refreshTokenPromise
    }
    this.isRequestingToken = true
    // ensure latest refresh token is used
    this.updateRefreshTokenFromStorage()
    const baseUrl = getCognitorApiBaseUrl(this.context)

    this.refreshTokenPromise = requestRefreshToken({
      axiosClient: createDefaultClient(this.context, ''),
      clientId: this.context.$config.cognitorClientId,
      refreshToken: this.refreshToken || '',
      endpoint: baseUrl + this.context.$config.cognitorRefreshTokenPath,
      scope,
    })
      .then((tokenData: TokenData) => {
        this.updateTokens({
          accessToken: tokenData.accessToken,
          refreshToken: tokenData.refreshToken,
          expiresIn: Number(tokenData.expiresIn),
          expiresAt: tokenData.expiresAt,
          saveAccessToken,
        })

        this.mitt.emit('TokenRefreshed', tokenData)

        return tokenData
      })
      .catch((e: any) => {
        // when we load the page and have an outdated refresh token in cookie, we get here
        // remove tokens to prevent re-initialization from cookies with outdated tokens
        if (e?.response?.status === 400 || e?.response?.status === 401) {
          this.removeTokens()
        }
        return Promise.reject(e)
      })
      .finally(() => {
        this.isRequestingToken = false
        this.refreshTokenPromise = null
      })

    return this.refreshTokenPromise
  }

  public hydrateOrganizationIdFromQuery() {
    // hydrate organization id from query
    this.organizationId = (this.context.route.query.o as string | null) ?? null
  }

  /**
   * Hydrate this auth service on server side.
   */
  public hydrateAuthTokensFromCookie() {
    // Get tokens from cookies or localStorage
    const accessToken = this.context.$cookies.get(this.storagePaths.jwtToken)
    const refreshToken = this.context.$cookies.get(
      this.storagePaths.refreshToken
    )

    // always set the refresh token, so maybe we can refresh the access token
    this.refreshToken = refreshToken

    if (!accessToken) {
      return
    }

    const jwtToken = jwtDecode<JWTToken>(accessToken)
    const expiresAt = dayjs.unix(jwtToken.exp)

    this.updateTokens({
      accessToken: accessToken,
      refreshToken: refreshToken,
      expiresIn: dayjs().diff(expiresAt, 'second'),
      expiresAt: dayjs.unix(jwtToken.exp).toISOString(),
      saveAccessToken: false,
    })
  }

  public hydrateAuthTokensFromQuery() {
    const accessToken = this.context.query[this.storagePaths.jwtToken] ?? null
    if (!accessToken) {
      return
    }

    const jwtToken = jwtDecode<JWTToken>(String(accessToken))
    const expiresAt = dayjs.unix(jwtToken.exp)

    // check if access token has been expired
    const now = dayjs()
    const expiry = dayjs(expiresAt)
    if (now.isAfter(expiry)) {
      return
    }

    this.updateTokens({
      accessToken: String(accessToken),
      expiresIn: dayjs().diff(expiresAt, 'second'),
      expiresAt: dayjs.unix(jwtToken.exp).toISOString(),
      saveAccessToken: false,
    })
  }
  /**
   * Hydrate service from client side.
   * All ids should be in the store and the objects
   * request from the server.
   */
  public hydrateInstancesFromIds() {
    if (this.customerAccountId) {
      this.account = CustomerAccount.fromId(
        this.customerAccountId,
        this.context.$jsonApiService
      )
    }

    if (this.userId) {
      this._user = User.fromId(this.userId, this.context.$jsonApiService)
    }

    if (this.organizationId) {
      this._organization = Organization.fromId(
        this.organizationId,
        this.context.$jsonApiService
      )
    }

    if (this.cognitorUserId) {
      this._cognitorUser = CognitorUser.fromId(
        this.cognitorUserId,
        this.context.$jsonApiService
      )
    }
  }

  /**
   * Request customer account
   */
  public async requestCustomerAccount(destruct = false) {
    // if we don't have an access token, skip it
    if (!this.hasAccessToken && !this.hasRefreshToken) {
      return
    }
    // potentially refresh access token
    await this.getAccessToken()
    // request the customer account
    const account = await CustomerAccount.requestUser(
      this.context.$jsonApiService
    )
    // change account
    await this.changeCustomerAccount(account)

    // optionally destruct requested account
    if (destruct) {
      this.account?.$destruct()
    }
  }

  /**
   * If watcher should be run immediately the
   * first parameter is the current customer account.
   *
   * @param watcher
   * @param runImmediately
   */
  public async watchCustomerAccountChanged(
    watcher: CustomerAccountWatcher,
    runImmediately = false
  ) {
    this._accountWatchers.push(watcher)

    if (runImmediately) {
      await watcher(this.account, null)
    }
  }

  public async changeCustomerAccount(
    newCustomerAccount: CustomerAccount | null
  ) {
    const oldAccount = this.account
    this.account = newCustomerAccount
    this.customerAccountId = newCustomerAccount?.id ?? null

    for (const watcher of this._accountWatchers) {
      await watcher(this.account, newCustomerAccount)
    }

    oldAccount?.$destruct()
  }

  public async requestUser(destruct = false) {
    const builder = User.api(this.context.$jsonApiService)
      .with([
        'abilities',
        'roles.abilities',
        'profileImage',
        'activeOrganization.logoImage',
      ])
      .select({
        organizationUsers: ['organization'],
        organizations: ['name', 'logo_image'],
      })
    builder.path = User.apiPath
    const response = await builder.request('user')
    this._user = User.resourceFromResponse(
      response.data,
      this.context.$jsonApiService
    ).data
    this.userId = this._user!.id

    if (destruct) {
      this.user?.$destruct()
    }
  }

  public async requestOrganization(
    destruct = false,
    include: null | string[] = null
  ) {
    const builder = Organization.api(this.context.$jsonApiService)
      .with(
        include ?? [
          'logoImage',
          'negativeLogoImage',
          'industry',
          'main_subscription.plan',
          'location',
        ]
      )
      .query({
        include_summary: true,
      })
      .select({
        industries: ['slug', 'name', 'config', 'offer_type', 'icon'],
      })
    builder.path = Organization.apiPath
    const response = await builder.request('organization')
    const organization = response.data?.data
      ? Organization.resourceFromResponse(
          response.data,
          this.context.$jsonApiService
        ).data
      : null

    this.changeOrganization(organization)

    if (destruct) {
      this._organization?.$destruct()
    }
  }

  public changeOrganization(organization: Organization | null) {
    const oldOrganization = this._organization
    this._organization = organization
    this.organizationId = organization?.id ?? null
    oldOrganization?.$destruct()
  }

  public async requestCognitorUser(destruct = false) {
    const builder = CognitorUser.api(this.context.$jsonApiService)
    builder.path = CognitorUser.apiPath
    const response = await builder.request('user')
    const user = CognitorUser.resourceFromResponse(
      response.data,
      this.context.$jsonApiService
    ).data

    this.changeCognitorUser(user)

    if (destruct) {
      this._cognitorUser?.$destruct()
    }
  }

  public changeCognitorUser(user: CognitorUser | null) {
    const oldCognitorUser = this._cognitorUser
    this._cognitorUser = user
    this.cognitorUserId = user?.id ?? null
    oldCognitorUser?.$destruct()
  }

  redirectToLogin() {
    this.redirectedToLogin = true
    return this.context.redirect(
      this.context.app.localePath({
        name: 'login',
        query: {
          target: this.context.route.fullPath,
          invalidToken: 'true',
        },
      })
    )
  }
}

type AuthServiceFactoryOptions = {
  withUser?: boolean
  withCustomer?: boolean
  withOrganization?: boolean
  withCognitorUser?: boolean
  hydrateTokens?: boolean
}

export const initAuthServicePluginFactory = ({
  withCustomer = false,
  withUser = false,
  withOrganization = false,
  withCognitorUser = false,
  hydrateTokens = true,
}: AuthServiceFactoryOptions): Plugin => {
  return async function (ctx: Context, inject: Inject) {
    const service = new AuthService(ctx, ctx.$config.storagePrefix)
    const observableService = Vue.observable(service)

    if (process.server) {
      service.hydrateOrganizationIdFromQuery()
      // TODO: add callbacks when user or o^rg changes
      if (hydrateTokens && !ctx.$embedService) {
        service.hydrateAuthTokensFromCookie()
      } else if (hydrateTokens && ctx.$embedService) {
        service.hydrateAuthTokensFromQuery()
      }
    } else if (process.client) {
      service.hydrateInstancesFromIds()
      // put latest tokens in localStorage
      // the refresh token may have been updated during SSR
      // so the localStorage refresh token is not up to date
      if (service.refreshToken && 'localStorage' in window) {
        window.localStorage.setItem(
          service.storagePaths.refreshToken,
          service.refreshToken
        )
      }
    }

    inject('authService', observableService)

    // After injecting, request the user
    try {
      if (
        withCustomer &&
        process.server &&
        (service.hasAccessToken || service.hasRefreshToken)
      ) {
        await observableService.requestCustomerAccount(true)
      }

      if (
        withUser &&
        process.server &&
        (service.hasAccessToken || service.hasRefreshToken)
      ) {
        await observableService.requestUser(true)
      }

      if (
        withOrganization &&
        process.server &&
        (service.hasAccessToken || service.hasRefreshToken)
      ) {
        // keep instance reactive on server side
        await observableService.requestOrganization(false)
      }

      if (
        withCognitorUser &&
        process.server &&
        (service.hasAccessToken || service.hasRefreshToken)
      ) {
        await observableService.requestCognitorUser(true)
      }
    } catch (e) {
      if (ctx.$annyDebugRequestId) {
        console.log(
          'could not request user, customerAccount or organizaton in plugin',
          ctx.$annyDebugRequestId
        )
      }
    }
  }
}
