import { ApiResourceBase, Attr, BelongsTo } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'

export class Favorite extends ApiResource {
  static jsonApiType = 'favorites'
  @Attr() liked: boolean
  @Attr() recent: boolean
  @Attr() viewed: boolean
  @Attr() model_id: string
  @Attr() model_type: string
  @Attr() created_at: string
  @Attr() order_index: number
  @Attr() orderIndex: number
  @BelongsTo() model: ApiResourceBase
}
