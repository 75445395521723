import dayjs, { Dayjs } from 'dayjs'
import { lcFirst } from '@/shared/utils/StrHelpers'
import VueI18n from 'vue-i18n'
import { ServiceUnit } from '@/shared/jsonapi-orm/bookingbuddy/Service'

export function getLocalizedDuration(
  date1: string | Dayjs | Date,
  date2: string | Dayjs | Date,
  i18n: VueI18n,
  unit?: ServiceUnit
): string {
  const dayjs1 = dayjs(date1)
  const dayjs2 = dayjs(date2)
  if (unit) {
    const total = calculateDuration(dayjs1, dayjs2, unit)

    return getLocalizedStringFromUnit(total, unit, i18n)
  }
  const totalSeconds = Math.abs(dayjs1.diff(dayjs2, 'second'))
  return getLocalizedStringFromSeconds(totalSeconds, i18n)
}

/**
 * Return localized String from total amount of seconds
 * @param {number}totalSeconds
 * @param i18n {VueI18n}
 * @param trimOne
 * @returns {string|*}
 */
export function getLocalizedStringFromSeconds(
  totalSeconds: number,
  i18n: VueI18n,
  trimOne = false
): string {
  const seconds = Math.floor(totalSeconds % 60)
  let minutes = Math.floor((totalSeconds / 60) % 60)
  let hours = Math.floor((totalSeconds / (60 * 60)) % 24)
  const days = Math.floor((totalSeconds / (60 * 60 * 24)) % 7)
  const weeks = Math.floor(totalSeconds / (60 * 60 * 24 * 7))
  const durations = []
  const sum = weeks + days + hours + minutes + seconds
  if (weeks > 0) {
    durations.push(lcFirst(i18n.tc('common.datetime.week', weeks)))
  }
  if (days > 0) {
    durations.push(lcFirst(i18n.tc('common.datetime.day', days)))
  }
  if (hours > 0) {
    // add half hours
    if (minutes === 30) {
      hours += 0.5
      minutes = 0
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    durations.push(lcFirst(i18n.tc('common.datetime.hour', i18n.n(hours))))
  }
  if (minutes > 0) {
    durations.push(lcFirst(i18n.tc('common.datetime.minute', minutes)))
  }
  if (seconds > 0) {
    durations.push(lcFirst(i18n.tc('common.datetime.second', seconds)))
  }
  // check if count is 1 -> then trim one
  if (sum === 1 && trimOne) {
    const duration = String(durations[0])
    durations[0] = duration.substr(duration.indexOf(' ') + 1)
  }

  return durations.join(', ')

  // if (Intl && 'ListFormat' in Intl) {
  //     // Intl list format
  //     // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/ListFormat
  //     const formatter = new Intl.ListFormat(i18n.locale, { style: 'long', type: 'conjunction' });
  //     return formatter.format(durations)
  // } else {
  //     // fallback
  //
  // }
}

export function calculateDuration(
  date1: string | Dayjs | Date,
  date2: string | Dayjs | Date,
  unit: ServiceUnit
): number {
  const dayjs1 = dayjs(date1)
  const dayjs2 = dayjs(date2)
  if (unit === ServiceUnit.MINUTE || unit === ServiceUnit.HOUR) {
    return Math.abs(dayjs1.diff(dayjs2, unit))
  }
  // ceil diff in other units (day, week, month, quarter, year)
  return Math.ceil(Math.abs(dayjs1.diff(dayjs2, unit, true)))
}

export function getLocalizedStringFromUnit(
  duration: number,
  unit: ServiceUnit,
  i18n: VueI18n,
  lowercase = false,
  trimOne = false
) {
  let formatted = ''
  if (unit === ServiceUnit.YEAR) {
    formatted = i18n.tc('common.datetime.year', duration)
  } else if (unit === ServiceUnit.QUARTER) {
    formatted = i18n.tc('common.datetime.quarter', duration)
  } else if (unit === ServiceUnit.MONTH) {
    formatted = i18n.tc('common.datetime.month', duration)
  } else if (unit === ServiceUnit.WEEK) {
    formatted = i18n.tc('common.datetime.week', duration)
  } else if (unit === ServiceUnit.DAY) {
    formatted = i18n.tc('common.datetime.day', duration)
  } else if (unit === ServiceUnit.HOUR) {
    formatted = i18n.tc('common.datetime.hour', duration)
  } else if (unit === ServiceUnit.MINUTE) {
    if (duration >= 60) {
      // find better format
      return getLocalizedStringFromSeconds(duration * 60, i18n, trimOne)
    }
    formatted = i18n.tc('common.datetime.minute', duration)
  }

  if (lowercase) {
    formatted = lcFirst(formatted)
  }

  // trim first part of string (e.g. "one" in "one week")
  if (trimOne && duration === 1) {
    formatted = formatted.substr(formatted.indexOf(' ') + 1)
  }
  return formatted
}
