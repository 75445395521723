// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@nuxt/webpack/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryColor": "#6494ff",
	"primaryColorRgb": "100,148,255",
	"annyColor": "#7474d3",
	"primaryColorDark": "#131333",
	"primaryColorHover": "#7ea6ff",
	"primaryColorOverlay": "rgba(75,130,255,.12)",
	"secondaryColor": "#ff9814",
	"successColor": "#16a085",
	"successColorRgb": "22,160,133",
	"successColorOverlay": "rgba(22,160,133,.25)",
	"successColorLight": "#19b698",
	"infoColor": "#1be1d3",
	"dangerColor": "#e64759",
	"dangerColorRgb": "230,71,89",
	"dangerColorLight": "#e95d6d",
	"dangerColorOverlay": "rgba(230,71,89,.25)",
	"warningColor": "#e2a74f",
	"warningColorRgb": "226,167,79",
	"warningColorOverlay": "rgba(226,167,79,.25)",
	"lightColor": "dimgray",
	"darkColor": "#5c5c5c",
	"textPrimaryColor": "#fff",
	"textSecondaryColor": "#d0d2d1",
	"textTertiaryColor": "#7f878e",
	"primaryBackground": "#090919",
	"primaryBackground-rgb": "9,9,25",
	"primaryBackground-dark": "#090919",
	"panelBackground": "#252536",
	"panelBackground-rgb": "37,37,54",
	"panelBackgroundLight": "#2f2f45",
	"panelBackgroundOverlay": "rgba(37,37,54,.25)",
	"panelBackgroundOverlayDense": "rgba(37,37,54,.85)",
	"panelBackgroundDark": "#32374c",
	"inputBackground": "#32374c",
	"hoverBackground": "#3e445e",
	"sidebarBackground": "#090919",
	"heatmapBackground": "rgba(37,37,54,0)",
	"topbarBackground": "#090919",
	"tableBorderColor": "#353740",
	"lightBorderColor": "#3c3e48",
	"panelShadow": "0 5px 15px 0 rgba(32,32,32,.4)",
	"hoverShadow": "0 5px 20px 0 hsla(0,0%,9%,.5)",
	"dropdownShadow": "0 5px 10px 0 rgba(32,32,32,.8)",
	"detailShadow": "0 3px 6px 0 rgba(32,32,32,.5)",
	"minimalShadow": "0 1px 2px 0 rgba(62,62,62,.5)",
	"heroGradientStart": "#11114e",
	"heroGradientEnd": "#512514",
	"colorPalette": "#5fbbd0,#5b8fdd,#8e5dca,#d33e8a,#e7445e,#ed8047,#f8b52c,#eded5d,#9aed63"
};
module.exports = ___CSS_LOADER_EXPORT___;
