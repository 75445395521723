import { updateField } from 'vuex-map-fields'
import { ActionTree, GetterTree, MutationTree, StoreOptions } from 'vuex'

type JWTToken = {
  aud: string // audience -> client_id
  jti: string // jwt token id
  iat: number // issued at
  nbf: number // not valid before
  exp: number // expires_at
  sub: string // subject/owner -> user_id
  scopes: string[] // allowed scopes
}

export type AuthState = {
  jwtToken: JWTToken | null
  accessToken: string | null
  refreshToken: string | null
  expiresIn: number | null // number of seconds until expiry
  expiresAt: string | null // iso representation of expiry datetime
  customerAccountId: string | null
  userId: string | null
  organizationId: string | null
  cognitorUserId: string | null
}

type AuthStoreOptions<S extends Record<string, any>> = {
  state: S
  getters?: GetterTree<AuthState & S, any>
  mutations?: MutationTree<AuthState & S>
  actions?: ActionTree<AuthState & S, any>
}

interface AuthStore<S> extends StoreOptions<S> {
  state: () => S
}

export default function <S>(options: AuthStoreOptions<S>) {
  const getDefaultState = (): AuthState & S => ({
    jwtToken: null,
    accessToken: null,
    refreshToken: null,
    expiresIn: null,
    expiresAt: null,
    customerAccountId: null,
    userId: null,
    organizationId: null,
    cognitorUserId: null,
    ...options.state,
  })
  const authStore: AuthStore<AuthState & S> = {
    state: getDefaultState,
    getters: {},
    mutations: {
      updateField,
      setAccessToken(state, value) {
        state.accessToken = value
      },
      setRefreshToken(state, value) {
        state.refreshToken = value
      },
      setJwtToken(state, value) {
        state.jwtToken = value
      },
      setExpiresIn(state, value) {
        state.expiresIn = value
      },
      setExpiresAt(state, value) {
        state.expiresAt = value
      },
      setCustomerAccountId(state, value) {
        state.customerAccountId = value
      },
      setUserId(state, value) {
        state.userId = value
      },
      setOrganizationId(state, value) {
        state.organizationId = value
      },
      setCognitorUserId(state, value) {
        state.cognitorUserId = value
      },
      resetState(state) {
        const s = getDefaultState()
        Object.keys(s).forEach((key) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          state[key] = s[key]
        })
      },
      ...options.mutations,
    },
    actions: {},
  }
  return authStore
}
