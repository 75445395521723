/*
 * Add dayjs plugins
 */
import { Context, Plugin } from '@nuxt/types'
import dayjs from 'dayjs'
// https://github.com/iamkun/dayjs/blob/master/docs/en/Plugin.md#localizedformat
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
// https://github.com/iamkun/dayjs/blob/master/docs/en/Plugin.md#relativetime
import relativeTime from 'dayjs/plugin/relativeTime'
import isoWeek from 'dayjs/plugin/isoWeek'
import timezone from 'dayjs/plugin/timezone'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'

dayjs.extend(LocalizedFormat)
dayjs.extend(relativeTime)
dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isoWeek)
dayjs.extend(isSameOrBefore)

declare module 'vue/types/vue' {
  interface Vue {
    $dayjs: typeof dayjs
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $dayjs: typeof dayjs
  }
  interface Context {
    $dayjs: typeof dayjs
  }
}

const plugin: Plugin = function (ctx: Context, inject) {
  inject('dayjs', dayjs)
}
export default plugin
