import Vue from 'vue'
import { imageLoaded, loadImage } from '../utils/MediaHelpers'
import { removeLeadingSlash } from '@/shared/utils/StrHelpers'

/**
 * Easy passing of events across nested components
 * v-bubble.eventName
 *
 * @link https://gist.github.com/loilo/597fa84f4d7e6b7552373f2df9517b45
 */
Vue.directive('bubble', (el, binding, vnode) => {
  Object.keys(binding.modifiers).forEach((event) => {
    // Bubble events of Vue components
    if (vnode.componentInstance) {
      vnode.componentInstance.$off(event)
      vnode.componentInstance.$on(event, (...args: any[]) => {
        vnode.context?.$emit(event, ...args)
      })

      // Bubble events of native DOM elements
    } else {
      el.addEventListener(event, (payload) => {
        vnode.context?.$emit(event, payload)
      })
    }
  })
})

/**
 * Smooth background images
 */
Vue.directive('background-image', {
  // binding.value in ="value"
  // binding.arg v-directive:arg
  async bind(el, binding) {
    if (binding.modifiers.optional && !binding.value) {
      return
    }
    el.style.backgroundRepeat = 'no-repeat'
    el.style.backgroundPosition = 'center'
    if (binding.modifiers.cover) {
      el.style.backgroundSize = 'cover'
      el.style.backgroundColor = 'var(--panel-background-dark)'
    }
    if (binding.modifiers.contain) {
      el.style.backgroundSize = 'contain'
    }
    el.style.backgroundClip = 'padding-box'
    // Remove leading slash from url
    const url = removeLeadingSlash(binding.value)
    if (url) {
      try {
        if (!imageLoaded(url)) {
          // hide image before loading
          el.style.opacity = '0'
          el.style.transition = 'opacity 0.25s ease-in'
          await loadImage(url)
        }
        el.style.backgroundImage = 'url(' + url + ')'
      } catch (e) {
        el.style.backgroundColor = 'var(--panel-background-dark)'
        console.log(e)
      } finally {
        // display image
        el.style.opacity = '1'
      }
    }
  },
  update(el, binding) {
    // remove leading slash
    const url = removeLeadingSlash(binding.value)

    if (el.style.backgroundImage && !binding.value) {
      el.style.backgroundImage = 'none'
    }
    if (url) {
      el.style.backgroundImage = 'url(' + url + ')'
    }
  },
})

const scrollListenerMap = new Map()

/**
 * Apply class based on scroll position
 * @link https://css-tricks.com/styling-based-on-scroll-position/
 * value: { y: number, class: string }
 */
Vue.directive('scroll-position', {
  bind(el, binding) {
    // get classes array from string
    const classes = binding.value.class.split(' ')
    // Listen for new scroll events, here we debounce our `storeScroll` function
    const listener = debounce(() => handleScroll(el, binding.value.y, classes))
    scrollListenerMap.set(el, listener)
    document.addEventListener('scroll', listener, { passive: true })
    // Update scroll position for first time
    handleScroll(el, binding.value.y, classes)
  },
  unbind(el, binding) {
    document.removeEventListener('scroll', scrollListenerMap.get(el))
    scrollListenerMap.delete(el)
  },
})

/**
 * The debounce function receives our function as a parameter
 *
 * @param fn
 * @return {function(...[*]=)}
 */
const debounce = (fn: (...arg: any[]) => void) => {
  // This holds the requestAnimationFrame reference, so we can cancel it if we wish
  let frame: number
  // The debounce function returns a new function that can receive a variable number of arguments
  return (...params: any[]) => {
    // If the frame variable has been defined, clear it now, and queue for next frame
    if (frame) {
      cancelAnimationFrame(frame)
    }
    // Queue our function call for the next frame
    frame = requestAnimationFrame(() => {
      // Call our function and pass any params we received
      fn(...params)
    })
  }
}

/**
 *  Add or remove classes from classList
 * @param el {HTMLElement}
 * @param pos {number}
 * @param classes {string[]}
 */
const handleScroll = (el: HTMLElement, pos: number, classes: string[]) => {
  const position = window.scrollY
  if (position >= pos) {
    // add classes
    if (el) {
      el.classList.add(...classes)
    }
  } else {
    // remove classes
    if (el) {
      el.classList.remove(...classes)
    }
  }
}
