import { Attr, HasMany } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { Ability } from '@/shared/jsonapi-orm/auth/Ability'

export class Role extends ApiResource {
  static jsonApiType = 'roles'
  @Attr() name: string
  @Attr() title: string | null
  @Attr(false) isOwnerRole: boolean
  @HasMany() abilities?: Ability[]

  async duplicate() {
    const { data } = await this.api().include('abilities').request('duplicate')
    return Role.resourceFromResponse(data, this.apiService).data
  }
}
