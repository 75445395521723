import { Organization } from '@/shared/jsonapi-orm/bookingbuddy/Organization'
import { Resource } from '@/shared/jsonapi-orm/bookingbuddy/Resource'
import { Payment } from '@/shared/jsonapi-orm/payments/Payment'
import { getQueryParamsString } from '@/shared/types/UrlBuilder'
import { Service } from '@/shared/jsonapi-orm/bookingbuddy/Service'
import { TestResult } from '@/shared/jsonapi-orm/tests/TestResult'
import { Community } from '@/shared/jsonapi-orm/bookingbuddy/Community'

export class LinkService {
  apiBaseUrl: string
  shopBaseUrl: string

  constructor(apiBaseUrl: string, shopBaseUrl: string) {
    this.apiBaseUrl = apiBaseUrl
    this.shopBaseUrl = shopBaseUrl
  }

  /**
   * Generate add to calendar url
   * @param {string} bookingId
   * @param {string} type = ics|google|yahoo|webOutlook
   * @param {string} code
   * @return {string}
   */
  createCalendarLink(bookingId: string | number, type: string, code: string) {
    return encodeURI(
      `${this.apiBaseUrl}/add-to-calendar/booking/${bookingId}?type=${type}&code=${code}`
    )
  }

  /**
   * Generate download all booking tickets url
   * @param {string} orderId
   * @param {string} accessToken
   * @return {string}
   */
  createDownloadAllBookingsLink(orderId: string | number, accessToken: string) {
    return encodeURI(
      `${this.apiBaseUrl}/downloads/booking-tickets/${orderId}?access_token=${accessToken}`
    )
  }

  /**
   * Create link for downloading a single booking
   * @param bookingId
   * @param code
   * @return {string}
   */
  createDownloadSingleBookingLink(bookingId: string | number, code: string) {
    return encodeURI(
      `${this.apiBaseUrl}/downloads/booking-ticket/${bookingId}?code=${code}`
    )
  }

  /**
   * Create link for downloading a single booking
   * @param bookingId
   * @param code
   * @return {string}
   */
  createDownloadBookingLabelLink(bookingId: string | number, code?: string) {
    return encodeURI(
      `${this.apiBaseUrl}/downloads/booking-label/${bookingId}?code=${code}`
    )
  }

  /**
   * Create link for downloading oegd for test result
   * @return {string}
   * @param testResult
   * @param options
   */
  createDownloadOegdLink(testResult: TestResult, options: Record<string, any>) {
    const params = new URLSearchParams(options)

    return (
      encodeURI(
        `${this.apiBaseUrl}/integrations/cwa/download-oegd/${testResult.id}`
      ) +
      '?' +
      params.toString()
    )
  }

  /**
   * Return a link for ticket pass on ios
   *
   * @param {string} bookingId
   * @param {string} code
   * @return {string}
   */
  createDownloadSingleBookingForIosLink(
    bookingId: string | number,
    code: string
  ) {
    return encodeURI(
      `${this.apiBaseUrl}/downloads/booking-pass/${bookingId}?code=${code}`
    )
  }

  /**
   *
   * @return {string}
   * @param bookingId
   * @param code
   */
  createDownloadSingleBookingForAndroidLink(
    bookingId: string | number,
    code: string
  ) {
    // since the android app is currently broken, we use the download like for ios
    return this.createDownloadSingleBookingForIosLink(bookingId, code)
    /*const bookingPassUrl = encodeURIComponent(
      this.createDownloadSingleBookingForIosLink(bookingId, code)
    )
    return encodeURI(`https://walletpass.io?u=${bookingPassUrl}`)*/
  }

  /**
   * Download account pass as wallet pass.
   * @param passId
   * @param code
   */
  createDownloadAccountPassForIosLink(passId: string | number, code: string) {
    return encodeURI(
      `${this.apiBaseUrl}/downloads/account-pass/${passId}.pkpass?code=${code}`
    )
  }

  /**
   * Download account pass for android wallet passes.
   * @param passId
   * @param code
   */
  createDownloadAccountPassForAndroidLink(
    passId: string | number,
    code: string
  ) {
    return this.createDownloadAccountPassForIosLink(passId, code)
  }

  /**
   * Download account pass for google pay.
   * @param passId
   * @param code
   */
  createDownloadAccountPassForGooglePayLink(
    passId: string | number,
    code: string
  ) {
    return encodeURI(
      `${this.apiBaseUrl}/downloads/account-pass-google-pay/${passId}?code=${code}`
    )
  }

  /**
   * Get shop url for organization
   */
  getOrganizationClientUrl(organization: Organization, shouldLogin = false, path?: string) {
    let url = encodeURI(`${this.shopBaseUrl}/${organization.slug}`)
    if (path) {
      url += `/${path}`
    }
    url += `?view=list`
    if (!organization.live && organization.previewToken) {
      url += `&preview_token=${organization.previewToken}`
    }
    if (shouldLogin) {
      url += `&shouldLogin=true`
    }
    return url
  }

  /**
   * Get shop url for community.
   * @param community
   * @param shouldLogin
   */
  getCommunityClientUrl(community: Community, shouldLogin = false) {
    let url = encodeURI(`${this.shopBaseUrl}/explore/c/${community.slug}`)
    if (shouldLogin) {
      url += `?shouldLogin=true`
    }
    return url
  }

  buildUrlWithQuery(
    url: string,
    queryParams: Record<string, any> = {},
    shouldLogin = false
  ) {
    if (shouldLogin) {
      queryParams = {
        ...queryParams,
        shouldLogin: true,
      }
    }

    // Build query string
    if (Object.keys(queryParams).length === 0) {
      return url
    }

    return `${url}?${getQueryParamsString(queryParams, false)}`
  }

  /**
   * Get shop url for a resource
   */
  getResourceClientUrl(
    resource: Resource,
    withPreviewToken = true,
    queryParams: Record<string, any> = {},
    forcePreviewToken = false,
    shouldLogin = false
  ) {
    const url = encodeURI(`${this.shopBaseUrl}/book/${resource.slug}`)
    if (
      forcePreviewToken ||
      (withPreviewToken && !resource.live && resource.previewToken)
    ) {
      queryParams = {
        ...queryParams,
        preview_token: resource.previewToken,
      }
    }
    return this.buildUrlWithQuery(url, queryParams, shouldLogin)
  }

  getServiceClientUrl(
    service: Service,
    queryParams: Record<string, any> = {},
    forcePreviewToken = false,
    previewToken: string | null = null,
    shouldLogin = false
  ) {
    const url = encodeURI(`${this.shopBaseUrl}/services/${service.slug}`)
    if (forcePreviewToken && previewToken) {
      queryParams = {
        ...queryParams,
        preview_token: previewToken,
      }
    }
    return this.buildUrlWithQuery(url, queryParams, shouldLogin)
  }

  /**
   * Get resource url with preselected service in query param.
   *
   * @param resource
   * @param service
   * @param withPreviewToken
   */
  getResourceClientUrlWithService(
    resource: Resource,
    service: Service,
    withPreviewToken = true
  ) {
    const queryParams: Record<string, any> = {
      s: service.slug,
    }
    if (service.accessCode) {
      queryParams.serviceAccessCode = service.accessCode
    }

    return this.getResourceClientUrl(resource, withPreviewToken, queryParams)
  }

  /**
   * Get payment status page url
   *
   * @param payment
   */
  getPaymentStatusUrl(payment: Payment) {
    return encodeURI(`${this.shopBaseUrl}/payments/${payment.id}`)
  }

  /**
   *
   * @param customerUuid
   */
  createDownloadCustomerDataLabelLink(customerUuid: string) {
    return encodeURI(
      `${this.apiBaseUrl}/downloads/customers/data-label/${customerUuid}`
    )
  }
}
