/*
 * Load dayjs locales async for i18n formatted dates
 * Docs: https://github.com/iamkun/dayjs
 */

import dayjs from 'dayjs'
import 'dayjs/locale/de'
import 'vue2-datepicker/locale/de'
import { Plugin } from '@nuxt/types'

const localeAlias = {
  'de-formal': 'de',
  'de-DE': 'de',
  'de-DE-formal': 'de',
  ua: 'uk',
}

const loadedLocales = ['de']

/**
 * Normalize locale code with alias
 * @param locale
 */
export function normalizeLocale(locale: string): string {
  if (locale in localeAlias) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return localeAlias[locale] as string
  }
  return locale
}

function getDayjsLocale(locale: string) {
  if (locale === 'en') {
    locale = 'en-gb'
  } else if (locale === 'en-us') {
    locale = 'en'
  } else if (locale === 'ua') {
    locale = 'uk'
  }
  return locale
}

/**
 * Load missing dayjs locale
 * @param locale
 * @returns {Promise<void>}
 */
async function loadDayjsLocale(locale: string) {
  // switch en defaults for dayjs
  locale = normalizeLocale(getDayjsLocale(locale))
  if (!loadedLocales.includes(locale)) {
    if (process.server) {
      // use sync import for nuxt server build
      require(`dayjs/locale/${locale}.js`)
    } else if (process.client) {
      // use async import on client
      await import(
        /* webpackChunkName: "dayjs-locale-[request]" */ `dayjs/locale/${locale}.js`
      )
    }
    dayjs.locale(locale)
  } else {
    dayjs.locale(locale)
  }
}
async function loadDatepickerLocale(locale: string) {
  if (locale === 'en-us') {
    locale = 'en'
  }
  locale = normalizeLocale(locale)
  if (!loadedLocales.includes(locale)) {
    // use sync import for nuxt server build and async import on client
    if (process.server) {
      // use sync import for nuxt server build
      require(`vue2-datepicker/locale/${locale}.js`)
    } else if (process.client) {
      // use async import on client
      await import(
        /* webpackChunkName: "vue2-datepicker-locale-[request]" */ `vue2-datepicker/locale/${locale}.js`
      )
    }
  }
}

/**
 * Handle locale changing
 * @param app
 */
const i18nPlugin: Plugin = async ({ app }) => {
  const locale = normalizeLocale(app.i18n.locale)

  await prepareLocale(locale)

  if (process.client) {
    // on language change
    app.i18n.onLanguageSwitched = (oldLocale, newLocale) => {
      prepareLocale(newLocale).then()
      if (process.client && window && '$chatwoot' in window) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.$chatwoot.setLocale(normalizeLocale(newLocale))
      }
      if (process.client && window && 'MessageBirdChatWidget' in window) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.MessageBirdChatWidget.setLanguage(normalizeLocale(newLocale))
      }
    }
  }
}

async function prepareLocale(locale: string) {
  try {
    // load dayjs locale
    // switch locale for datepicker to en
    await Promise.all([loadDayjsLocale(locale), loadDatepickerLocale(locale)])
  } catch (e) {
    console.log(e)
  }
}

export default i18nPlugin
