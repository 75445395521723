import { Attr, BelongsTo } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { AddOn } from '@/shared/jsonapi-orm/bookingbuddy/AddOn'
import { Booking } from '@/shared/jsonapi-orm/bookingbuddy/Booking'

export class BookingAddOn extends ApiResource {
  static jsonApiType = 'booking-add-ons'
  @Attr() quantity: number
  @Attr() total: number
  @Attr() chargedTotal: number
  @Attr() singleTotal: number
  @Attr() chargedSingleTotal: number

  @BelongsTo() addOn?: AddOn
  @BelongsTo() booking?: Booking
}
