import { Attr, BelongsTo } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '../ApiResource'
import { Resource } from '@/shared/jsonapi-orm/bookingbuddy/Resource'
import { Property } from '@/shared/jsonapi-orm/bookingbuddy/Property'

export enum ResourcePropertyValueType {
  BOOLEAN = 'boolean',
  NUMBER = 'number',
  STRING = 'string',
}

export class ResourceProperty extends ApiResource {
  static jsonApiType = 'resource-properties'
  static isPivotResource = true
  @Attr() value: string | number | boolean
  @Attr() valueType: ResourcePropertyValueType
  @Attr(0) orderIndex: number
  @BelongsTo() property: Property
  @BelongsTo() resource: Resource
}
