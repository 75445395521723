import { Attr, HasMany } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { ResourceCategory } from '@/shared/jsonapi-orm/bookingbuddy/ResourceCategory'

export enum UseCaseType {
  OFFER_SERVICES = 'offer-services',
  RENT_SPACES = 'rent-spaces',
  RENT_EQUIPMENT = 'rent-equipment',
  HOST_EVENTS = 'host-events',
  SCHEDULE_MEETINGS = 'schedule-meetings',
  RESOURCE_MANAGEMENT = 'resource-management',
  HYBRID_WORK = 'hybrid-work',
  EXPERIENCE_ADVENTURE = 'experience-adventure',
  MANAGE_MEMBERSHIPS = 'manage-memberships',
  WAITING_LISTS = 'waiting-lists',
  RESERVATIONS = 'reservations',
  OTHER = 'other',
}

export enum FormFlowType {
  RESOURCE_SERVICE = 'resource_service',
  RESOURCE_INTERNAL = 'resource_internal',
  SERVICE_STAFF = 'service_staff',
  ACTIVITY_LOCATION = 'activity_location',
  EVENT_TICKET = 'event_ticket',
  SERVICE_LOCATION = 'service_location',
  SUBSCRIPTION = 'subscription',
}

export interface IndustryWelcomeWidget {
  key: string
  title: string
  icon?: string
  body: string
  youtubeUrl?: string
  actions?: {
    name: string
    url: string
    newTab?: boolean
  }[]
}

export interface IndustryConfig {
  welcomeWidgets?: IndustryWelcomeWidget[]
  customLeadChannels?: string[]
  welcomeVideoYoutubeUrl?: string
}

export class Industry extends ApiResource {
  static jsonApiType = 'industries'
  @Attr() name: string | null
  @Attr() icon: string | null
  @Attr() description: string | null
  @Attr() illustration: string | null
  @Attr() hasTemplate: boolean
  @Attr() flowType: FormFlowType
  @Attr() useCases: UseCaseType[] | null
  @Attr() audience: 'internal' | 'external' | 'mixed'
  @Attr() config: IndustryConfig | null
  @Attr() offerType: 'rentals' | 'services'

  @HasMany() resourceCategories: ResourceCategory[]
}
