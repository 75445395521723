import { Attr, HasMany } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '../ApiResource'
import { Industry } from '@/shared/jsonapi-orm/bookingbuddy/Industry'

export class ResourceCategory extends ApiResource {
  static jsonApiType = 'resource-categories'
  @Attr() slug: string
  @Attr() name: string
  @Attr() pluralName: string
  @Attr() quantityLabel: string
  @Attr() pluralQuantityLabel: string
  @Attr() icon: string | null
  @Attr() schemaType: string
  @Attr() isLocation: boolean
  @Attr() isRentable: boolean
  @Attr() hasQuantity: boolean

  @HasMany() industries?: Industry[]
}
