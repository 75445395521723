import { Fields, KeyValuePair, Pagination, Sorting } from './JsonApiStore'

/**
 * Build query key for filters
 * @param baseKey
 * @param value
 */
function getFilterQueryKey(baseKey: string, value = ''): string {
  return `${baseKey}=${value}`
}

/**
 * filter: { key: String|Array[String,Number,Object] }
 * @param value
 * @param baseKey
 * @param snakeCase
 */
function getFilterQueryString(
  value: any,
  baseKey = 'filter',
  snakeCase = true
): string[] {
  const params: string[] = []
  // loop all keys of filter object
  // convert keys from camelCase to snake_case
  // serialize arrays
  if (value !== null && value !== undefined && value !== '') {
    // array
    if (Array.isArray(value)) {
      if (value.length > 0) {
        value.forEach((item) => {
          // get id of object if needed
          params.push(...getFilterQueryString(item, baseKey + '[]'))
        })
      } else {
        params.push(`${baseKey}=`)
      }
    }
    // object
    else if (value instanceof Object) {
      // only send id if available
      if (Object.prototype.hasOwnProperty.call(value, 'id')) {
        params.push(getFilterQueryKey(baseKey, value.id))
      } else {
        Object.keys(value).forEach((key) => {
          const nestedValue = value[key]
          const snakeKey = snakeCase
            ? key
                .split(/(?=[A-Z])/)
                .join('_')
                .toLowerCase()
            : key
          params.push(
            ...getFilterQueryString(nestedValue, `${baseKey}[${snakeKey}]`)
          )
        })
      }
    }
    // boolean
    else if (typeof value === 'boolean') {
      value = Number(value)
      params.push(getFilterQueryKey(baseKey, value))
    }
    // string
    else {
      value = encodeURIComponent(value)
      params.push(getFilterQueryKey(baseKey, value))
    }
  }
  return params
}

/**
 * @param fields
 */
function getFieldsQueryString(fields: Fields) {
  const params: string[] = []
  Object.keys(fields).forEach((key) => {
    const value = fields[key]
    // convert keys from camelCase to snake_case
    const snakeKey = key
      .split(/(?=[A-Z])/)
      .join('_')
      .toLowerCase()
    if (value !== null && value !== undefined) {
      // serialize arrays
      if (Array.isArray(value)) {
        params.push(`fields[${snakeKey}]=${value.join()}`)
      } else {
        params.push(`fields[${snakeKey}]=${value}`)
      }
    }
  })
  return params.length > 0 ? params.join('&') : ''
}

/**
 * @param queryParams
 * @param snakeCase
 */
export function getQueryParamsString(
  queryParams: KeyValuePair,
  snakeCase = true
) {
  let params: string[] = []
  Object.keys(queryParams).forEach((key: string) => {
    const value = queryParams[key]
    // convert keys from camelCase to snake_case
    const snakeKey = snakeCase
      ? key
          .split(/(?=[A-Z])/)
          .join('_')
          .toLowerCase()
      : key

    params = serializeValue(value, snakeKey, params, true)
  })
  return params.length > 0 ? params.join('&') : ''
}

function serializeValue(
  value: any,
  key: string,
  params: string[],
  serializeObject = false
) {
  if (value !== null && value !== undefined && value !== '') {
    // serialize arrays
    if (Array.isArray(value)) {
      value.forEach((item) => {
        // get id of object if needed
        item = typeof item === 'object' ? item.id : item
        // append [] to key to indicate array for decoding
        params.push(`${key}[]=${item}`)
      })
    } else if (typeof value === 'object') {
      if (serializeObject) {
        // Make nested object
        Object.keys(value).forEach(function (objKey) {
          const innerValue = value[objKey]
          // Serialize inner value
          const nestedKey = `${key}[${objKey}]`
          params = serializeValue(innerValue, nestedKey, params, false)
        })
      } else {
        // Serialize object with json stringify
        const jsonSerialized = encodeURIComponent(JSON.stringify(value))
        params.push(`${key}=${jsonSerialized}`)
      }
    } else {
      value = encodeURIComponent(value)
      params.push(`${key}=${value}`)
    }
  }

  return params
}

type UrlBuilderParams = {
  baseUrl?: string
  path: string
  include?: string[]
  pagination?: Pagination
  filter?: KeyValuePair
  sorting?: Sorting
  fields?: Fields
  queryParams?: KeyValuePair
  snakeParams?: boolean
  snakeFilter?: boolean
}

/**
 * UrlBuilder
 */
class UrlBuilder {
  baseUrl?: string
  path = ''
  include: string[]
  pagination?: Pagination
  filter: KeyValuePair
  sorting?: Sorting
  fields?: Fields
  queryParams?: KeyValuePair
  snakeParams: boolean
  snakeFilter: boolean

  /**
   * Create new UrlBuilder
   * @param baseUrl
   * @param path
   * @param include
   * @param pagination
   * @param filter
   * @param sorting
   * @param fields
   * @param queryParams
   * @param snakeParams
   * @param snakeFilter
   */
  constructor({
    baseUrl,
    path = '',
    include = [],
    pagination,
    filter = {},
    sorting,
    fields,
    queryParams = {},
    snakeParams = true,
    snakeFilter = true,
  }: UrlBuilderParams) {
    this.baseUrl = baseUrl
    this.path = path
    this.include = include ? include : []
    this.pagination = pagination
    this.filter = filter
    this.sorting = sorting
    this.fields = fields
    this.queryParams = queryParams
    this.snakeParams = snakeParams
    this.snakeFilter = snakeFilter
  }

  addInclude(include: string) {
    this.include.push(include)
  }

  addFilter({ key, value }: KeyValuePair) {
    this.filter[key] = value
  }

  addField(field: { [key: string]: string[] }) {
    this.fields = { ...this.fields, ...field }
  }

  get url(): string {
    let url = this.baseUrl !== undefined ? this.baseUrl : ''
    const params: string[] = []
    const baseUrlEndsWithSlash =
      url.length > 0 && url.substr(url.length - 1, 1) === '/'
    const pathBeginsWithSlash = this.path.substr(0, 1) === '/'
    const pathEndsWithSlash =
      this.path.length > 0 && this.path.substr(this.path.length - 1, 1) === '/'
    // add path to url
    if (baseUrlEndsWithSlash && pathBeginsWithSlash) {
      // trim "/" from base url
      url = url.substr(0, url.length - 1) + this.path
    } else if (baseUrlEndsWithSlash || pathBeginsWithSlash) {
      url += this.path
    } else {
      url += '/' + this.path
    }
    if (pathEndsWithSlash) url = url.substr(0, url.length - 1)
    // add include
    if (this.include && this.include.length > 0) {
      params.push(`include=${this.include.join(',')}`)
    }
    // add pagination
    if (this.pagination) {
      if (Object.prototype.hasOwnProperty.call(this.pagination, 'size')) {
        params.push(
          `page[number]=${
            Object.prototype.hasOwnProperty.call(
              this.pagination,
              'current_page'
            )
              ? this.pagination.current_page
              : 1
          }&page[size]=${this.pagination.size}`
        )
      } else if (
        Object.prototype.hasOwnProperty.call(this.pagination, 'limit')
      ) {
        params.push(
          `page[limit]=${this.pagination.limit}&page[offset]=${
            Object.prototype.hasOwnProperty.call(this.pagination, 'offset')
              ? this.pagination.offset
              : 0
          }`
        )
      }
    }
    // add filters
    if (this.filter) {
      const filterParams = getFilterQueryString(
        this.filter,
        'filter',
        this.snakeFilter
      )
      params.push(filterParams.join('&'))
    }
    // add sorting
    if (this.sorting && this.sorting.sort) {
      params.push(
        `sort=${this.sorting.order === 'asc' ? '' : '-'}${this.sorting.sort}`
      )
    }
    // add fields
    if (this.fields) {
      params.push(getFieldsQueryString(this.fields))
    }
    // add any additional query params
    if (this.queryParams) {
      params.push(getQueryParamsString(this.queryParams, this.snakeParams))
    }
    // add params to url
    if (params.length > 0) {
      url += '?' + params.filter((p) => p !== '').join('&')
    }
    return url
  }

  /**
   * conditional check for given value
   * @param condition
   * @param callback insert UrlBuilder, inserts 'this'
   */
  when(
    condition: boolean,
    callback: (builder: UrlBuilder) => void
  ): UrlBuilder {
    if (condition) {
      callback(this)
    }

    return this
  }
}

export { UrlBuilder }
