import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { BelongsTo } from '@anny.co/vue-jsonapi-orm'
import { Organization } from '@/shared/jsonapi-orm/bookingbuddy/Organization'
import { Queue } from '@/shared/jsonapi-orm/queues/Queue'
import { Resource } from '@/shared/jsonapi-orm/bookingbuddy/Resource'
import { Service } from '@/shared/jsonapi-orm/bookingbuddy/Service'

export type QueueRuleSuggestion = {
  resource_id: number
  resource_name: string
  resource_cover_url?: string
  service_id: number
  service_name: string
}

export class QueueRule extends ApiResource {
  static jsonApiType = 'queue-rules'

  @BelongsTo() organization?: Organization
  @BelongsTo() resource?: Resource
  @BelongsTo() service?: Service | null
  @BelongsTo() queue?: Queue
}
