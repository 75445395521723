import { Ability } from '@/shared/jsonapi-orm/auth/Ability'
import { JsonApiIdentifier } from '@anny.co/vue-jsonapi-orm/dist/JsonApiTypes'

export class Bouncer {
  // map actions to entities
  private entityActionMap: Map<string, string[]>
  // map entities to actions
  private actionMap: Map<string, string[]>
  private simpleActions: string[]

  constructor(abilities: Ability[] = []) {
    this.entityActionMap = new Map()
    this.actionMap = new Map()
    this.simpleActions = []
    abilities.forEach((ability: Ability) => {
      if (ability.entityType) {
        const key = ability.entityId
          ? `${ability.entityType}.${ability.entityId}`
          : ability.entityType
        const newEntities: string[] = this.entityActionMap.get(key) ?? []
        this.entityActionMap.set(key, [...newEntities, ability.name])
        // handle actionMap
        const entriesForAction: string[] =
          this.actionMap.get(ability.name) ?? []
        this.actionMap.set(ability.name, [...entriesForAction, key])
      } else {
        this.simpleActions.push(ability.name)
      }
    })
  }

  /**
   * Check if user can action for argument (entity or entityType)
   * @param action
   * @param argument
   */
  public can(action: string, argument?: string | JsonApiIdentifier): boolean {
    if (!argument) {
      return this.check(action)
    } else if (typeof argument === 'string') {
      return this.check(action, argument)
    } else {
      return this.check(action, argument?.type, argument?.id)
    }
  }

  /**
   * Check if user can all actions for argument
   * @param actions
   * @param argument
   */
  public canAll(
    actions: string[],
    argument?: string | JsonApiIdentifier
  ): boolean {
    for (const action of actions) {
      if (!this.can(action, argument)) {
        return false
      }
    }
    return true
  }

  /**
   * Check if user can any action for argument
   * @param actions
   * @param argument
   */
  public canAny(
    actions: string[],
    argument?: string | JsonApiIdentifier
  ): boolean {
    for (const action of actions) {
      if (this.can(action, argument)) {
        return true
      }
    }
    return false
  }

  /**
   * Check if user can do action on any instance of entity.
   * @param action
   * @param entity
   */
  public canForAny(action: string, entity: string): boolean {
    if (this.check(action, entity)) {
      return true
    }
    const entitiesForAction: string[] = this.actionMap.get(action) ?? []
    return entitiesForAction.some((entityWithAction) =>
      entityWithAction.startsWith(entity)
    )
  }

  /**
   * Check if user can action for all entities
   * @param entityType
   * @param entityId
   * @param action
   * @private
   */
  private check(
    action?: string,
    entityType?: string,
    entityId?: string
  ): boolean {
    if (entityType) {
      const globalActions = this.entityActionMap.get('*') ?? []
      const typeActions = this.entityActionMap.get(`${entityType}`) ?? []
      const entityActions =
        this.entityActionMap.get(`${entityType}.${entityId}`) ?? []
      const actions = [...globalActions, ...typeActions, ...entityActions]
      if (actions.includes('*')) {
        return true
      }
      if (action) {
        return actions.includes(action)
      }
    } else if (action) {
      return (
        this.simpleActions.includes(action) ||
        this.simpleActions.includes('*') ||
        this.check(action, '*')
      )
    }
    return false
  }
}
