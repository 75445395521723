import { Plugin } from '@nuxt/types'

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $logger: (...args: any[]) => void
  }
  interface Context {
    $logger: (...args: any[]) => void
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $logger: (...args: any[]) => void
  }
}

const loggerPlugin: Plugin = (context, inject) => {
  let logger = (...args: any[]) => {
    // null
  }

  if (context.$config.debugLogging) {
    logger = (...args: any[]) => {
      console.log(...args)
    }
  }

  inject('logger', logger)
}

export default loggerPlugin
