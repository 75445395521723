import axios, { AxiosInstance } from 'axios'
import tunnel from 'tunnel'

import {
  addAcceptLanguageInterceptor,
  addAccessTokenInterceptor,
  addActiveOrgInterceptor,
  addJsonApiErrorInterceptor,
  addNetworkErrorInterceptor,
  addNotFoundInterceptor,
  addOriginInterceptor,
  addProgressBarInterceptor,
  addUnauthenticatedInterceptor,
  addUnauthorizedInterceptor,
} from '../plugins/axiosInterceptors'
import { Context } from '@nuxt/types'

// 10 seconds timeout
const defaultTimeout = 10

/**
 * Check in context if proxy should use https over http
 * @param ctx
 */
const shouldUseHttpsOverHttp = (ctx: Context) => {
  return (
    !!ctx.$config.proxyUseHttpsOverHttp &&
    !Number.isNaN(ctx.$config.proxyPort) &&
    ctx.$config.proxyHost
  )
}

/**
 * Returns an agent for axios instance. This is used
 * when anny is running behind a proxy which uses https over http.
 *
 * @param ctx
 */
const getHttpAgent = (ctx: Context) => {
  return shouldUseHttpsOverHttp(ctx)
    ? tunnel.httpsOverHttp({
        proxy: {
          host: ctx.$config.proxyHost,
          port: ctx.$config.proxyPort,
        },
      })
    : null
}

/**
 * Add a cancel token source to all axios requests
 * to allow canceling all requests by axiosCancelSource.cancel()
 * then check in catch: if (axios.isCancel(e))
 */
export let cancelSource = axios.CancelToken.source()

export function cancelActiveAuthRequests() {
  cancelSource.cancel('Cancel request which need authentication')
  // create new source to allow further requests
  cancelSource = axios.CancelToken.source()
}

/**
 * Returns api base url based on ssr context.
 *
 * @param ctx
 * @param path
 */
export const getApiBaseUrl = (ctx: Context, path = '') => {
  if (process.server && ctx.$config.serverSideApiBaseUrl) {
    return ctx.$config.serverSideApiBaseUrl + path
  }

  return ctx.$config.apiBaseUrl + path
}

/**
 * Returns the base url for cognitor based on ssr context.
 *
 * @param ctx
 * @param path
 */
export const getCognitorApiBaseUrl = (ctx: Context, path = '') => {
  if (process.server && ctx.$config.cognitorServerSideBaseUri) {
    return ctx.$config.cognitorServerSideBaseUri + path
  }

  return ctx.$config.cognitorBaseUri + path
}

/**
 * Returns http client timeout based on ssr context in milliseconds.
 *
 * @param ctx
 */
export const getClientTimeout = (ctx?: Context) => {
  if (!ctx) {
    return defaultTimeout * 1000
  }

  const timeout = process.server
    ? ctx?.$config.apiRequestTimeoutSsr
    : ctx?.$config.apiRequestTimeout

  return timeout * 1000
}

/**
 * Remove all registered interceptors.
 * @param instance
 */
export function ejectAllInterceptors(instance: AxiosInstance) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  instance.interceptors.request.handlers = []
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  instance.interceptors.response.handlers = []
}

/**
 * Create a default axios client with json content type
 * @param apiPath
 * @param url
 * @param auth
 * @param ctx
 * @param withErrorHandling
 */
export const createDefaultClient = (
  ctx: Context,
  apiPath: '',
  url = '',
  auth = false,
  withErrorHandling = true
) => {
  const client = axios.create({
    baseURL: url ? url + apiPath : getApiBaseUrl(ctx, apiPath),
    timeout: getClientTimeout(ctx),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    httpsAgent: getHttpAgent(ctx),
  })
  // server log
  // addServerLogInterceptor(client)

  // addCancelInterceptor(client)
  if (withErrorHandling) {
    addNotFoundInterceptor(client, ctx)
  }
  addAcceptLanguageInterceptor(client, ctx)
  addOriginInterceptor(client, ctx)
  // addProgressBarInterceptor(client)
  addJsonApiErrorInterceptor(client, ctx)

  if (auth) {
    addAccessTokenInterceptor(client, false, ctx)
    addUnauthorizedInterceptor(client, ctx)
    addUnauthenticatedInterceptor(client, ctx)
    addActiveOrgInterceptor(client, ctx)
  }

  addNetworkErrorInterceptor(client, ctx)

  return client
}

/**
 * Create a public json api client. The path will always be appended.
 * If no url is given, the factory uses the base api url using the
 * getApiBaseUrl helper.
 *
 * @param ctx
 * @param apiPath
 * @param url
 */
export const createPublicJsonApiClient = (
  ctx: Context,
  apiPath: string,
  url = ''
) => {
  const client = axios.create({
    baseURL: url ? url + apiPath : getApiBaseUrl(ctx, apiPath),
    timeout: getClientTimeout(ctx),
    headers: {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
    httpsAgent: getHttpAgent(ctx),
    withCredentials: true,
  })
  // server log
  // addServerLogInterceptor(client)

  // addCancelInterceptor(client)
  addAcceptLanguageInterceptor(client, ctx)
  addOriginInterceptor(client, ctx)
  addProgressBarInterceptor(client)
  addJsonApiErrorInterceptor(client, ctx)
  addNetworkErrorInterceptor(client, ctx)

  return client
}

/**
 * Create an authenticated json:api client
 * @param url
 * @param accessToken
 * @param apiPath
 * @param ctx
 */
export const createAuthJsonApiClient = (
  ctx: Context,
  apiPath: string,
  url = '',
  accessToken = ''
) => {
  const client = axios.create({
    baseURL: url ? url + apiPath : getApiBaseUrl(ctx, apiPath),
    timeout: getClientTimeout(ctx),
    headers: {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
    httpsAgent: getHttpAgent(ctx),
    withCredentials: true,
  })
  // set accessToken from auth store module on every request
  if (accessToken === '') {
    addAccessTokenInterceptor(client, false, ctx)
  } else {
    // default to static accessToken provided as arg
    client.defaults.headers.Authorization = 'Bearer ' + accessToken
  }
  // server log
  // addServerLogInterceptor(client)

  // addCancelInterceptor(client)
  addAcceptLanguageInterceptor(client, ctx)
  addOriginInterceptor(client, ctx)
  addProgressBarInterceptor(client)
  addJsonApiErrorInterceptor(client, ctx)
  // only for auth client
  addUnauthorizedInterceptor(client, ctx)
  addUnauthenticatedInterceptor(client, ctx)
  addActiveOrgInterceptor(client, ctx)
  // network
  addNetworkErrorInterceptor(client, ctx)

  return client
}

/**
 * Will be an authenticated json:api client when a user is logged in
 * And an unauthentivated client else
 * @param url
 * @param apiPath
 * @param ctx
 */
export const createConditionalAuthJsonApiClient = (
  ctx: Context,
  apiPath: string,
  url = ''
) => {
  const client = axios.create({
    baseURL: url ? url + apiPath : getApiBaseUrl(ctx, apiPath),
    timeout: getClientTimeout(ctx),
    headers: {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
    httpsAgent: getHttpAgent(ctx),
    withCredentials: true,
  })
  // set accessToken from auth store module on every request
  addAccessTokenInterceptor(client, true, ctx)

  // addCancelInterceptor(client)
  addAcceptLanguageInterceptor(client, ctx)
  addOriginInterceptor(client, ctx)
  addProgressBarInterceptor(client)
  addJsonApiErrorInterceptor(client, ctx)
  // only for auth client
  addUnauthorizedInterceptor(client, ctx)
  addUnauthenticatedInterceptor(client, ctx)
  // network
  addNetworkErrorInterceptor(client, ctx)

  return client
}
