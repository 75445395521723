/**
 * Authenticate user before navigating to requested route
 * @param context {Context}
 */
import { Context } from '@nuxt/types'

export default async function (context: Context) {
  const { app } = context

  if (!context.$authService._user) {
    return context.redirect(
      context.app.localePath({
        name: 'panel-error',
        query: { reason: 'missingAccessToken' },
      })
    )
  }

  if (context.$authService.user?.locale !== app.i18n.locale) {
    await app.i18n.setLocale(context.$authService.user!.locale)
  }
}
