/**
 * User encoded url secret to log programmatic user in.
 * @param context {Context}
 */
import { Context } from '@nuxt/types'
import { DisplayPanel } from '@/shared/jsonapi-orm/bookingbuddy/DisplayPanel'

export default async function (context: Context) {
  const { $authService, query, params } = context
  if (params.panelId && query.secret) {
    let secret = String(query.secret)
    // trim ending of string (base 64 encoding ends with '=')
    if (secret.includes('=')) {
      secret = secret.substring(0, secret.indexOf('=') + 1)
    }
    try {
      const panel = await DisplayPanel.api(context.$jsonApiService)
        .query({ secret })
        .find(params.panelId)

      $authService.updateTokens({
        accessToken: panel.personalAccessToken ?? '',
        refreshToken: '',
        saveAccessToken: false,
      })
      await $authService.requestUser()
      await $authService.requestOrganization(false, ['logoImage', 'industry'])
    } catch (e) {
      if ('$sentry' in context) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        context.$sentry.captureException(e)
      } else {
        console.log(e)
      }
      return context.redirect(
        context.app.localePath({
          name: 'panel-error',
          query: { reason: 'requestError', message: e?.message },
        })
      )
    }
  }
}
