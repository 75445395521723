import { Attr } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import VueI18n from 'vue-i18n'

export interface AddOnPricing {
  price: number
  relative_price: number
  interval_price: number
}

export interface StaggeredAddOnPricing extends AddOnPricing {
  id: string
  min_quantity: number
  price: number
  relative_price: number
  interval_price: number
}

export class AddOn extends ApiResource {
  static jsonApiType = 'add-ons'
  @Attr('') name: string
  @Attr({}) localNameI18n?: Record<string, string> | null
  @Attr(null) description: string | null
  @Attr({}) descriptionI18n?: Record<string, string> | null
  @Attr(null) plainDescription: string | null
  @Attr('') customerNotice: string | null
  @Attr({}) customerNoticeI18n?: Record<string, string> | null
  @Attr(0) leadTime: number | null
  @Attr(false) isThirdParty: boolean
  @Attr('EUR') currency: string
  @Attr(19) taxRate: number
  @Attr(0) price: number
  @Attr(0) intervalPrice: number
  @Attr(0) relativePrice: number
  @Attr(0) minQuantity: number | null
  @Attr(null) maxQuantity: number | null
  @Attr(false) isNetPrice: boolean
  @Attr(false) featured: boolean
  @Attr(false) fullRefund: boolean
  @Attr() staggeredPricing: StaggeredAddOnPricing[] | null

  get hasStaggeredPricing(): boolean {
    return !!this.staggeredPricing && this.staggeredPricing.length > 0
  }

  /**
   * Get pricing for quantity.
   * @param quantity
   */
  getPricing(quantity?: number): AddOnPricing {
    if (
      quantity !== undefined &&
      this.staggeredPricing &&
      this.staggeredPricing.length > 0
    ) {
      const pricing = [...this.staggeredPricing].reverse().find((p) => {
        return p.min_quantity <= quantity
      })
      if (pricing) {
        return {
          price: Number(pricing.price),
          interval_price: Number(pricing.interval_price),
          relative_price: Number(pricing.relative_price),
        }
      }
    }
    return {
      price: this.price,
      interval_price: this.intervalPrice,
      relative_price: this.relativePrice,
    }
  }
}

export function getFormattedPrice(
  addOn: AddOn,
  pricing: AddOnPricing,
  i18n: VueI18n
): string {
  const priceLabels: string[] = []
  if (pricing.price) {
    priceLabels.push(
      i18n.n(pricing.price, {
        style: 'currency',
        currency: addOn.currency,
      })
    )
  }
  if (pricing.interval_price) {
    priceLabels.push(
      i18n.n(pricing.interval_price, {
        style: 'currency',
        currency: addOn.currency,
      }) + '*'
    )
  }
  if (pricing.relative_price) {
    priceLabels.push(
      i18n.n(pricing.relative_price / 100, {
        style: 'percent',
        maximumFractionDigits: 2,
      }) + '**'
    )
  }
  if (priceLabels.length === 0) {
    return i18n.t('common.terms.free').toString()
  }
  return priceLabels.join(' + ')
}
