import { Attr, BelongsTo } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '../ApiResource'
import { Resource } from '@/shared/jsonapi-orm/bookingbuddy/Resource'
import dayjs from 'dayjs'

export type Weekday = 1 | 2 | 3 | 4 | 5 | 6 | 7

export class Schedule extends ApiResource {
  static jsonApiType = 'schedules'

  @Attr() startTime: string | null
  @Attr() start_time: string | null
  @Attr('09:00') localStartTime: string
  @Attr() local_start_time: string
  @Attr() endTime: string | null
  @Attr() end_time: string | null
  @Attr('18:00') localEndTime: string
  @Attr() local_end_time: string
  @Attr() timezone: string
  @Attr(1) weekday: Weekday
  @Attr(null) quota: number | null

  // additional prop to map period for timepicker
  period?: [string, string]

  get dayjsStart(): dayjs.Dayjs | null {
    if (!this.localStartTime || !this.localEndTime || !this.weekday) {
      return null
    }
    return dayjs(this.localStartTime, 'HH:mm:ss', true).isoWeekday(this.weekday)
  }

  get dayjsEnd(): dayjs.Dayjs | null {
    if (
      !this.dayjsStart ||
      !this.localStartTime ||
      !this.localEndTime ||
      !this.weekday
    ) {
      return null
    }
    let end = dayjs(this.localEndTime, 'HH:mm:ss', true).isoWeekday(
      this.weekday
    )
    if (this.localEndTime <= this.localStartTime) {
      end = end.add(1, 'day')
    }
    return end
  }

  /**
   * Build dayjs representation of schedule.
   */
  get dayjsPeriod(): [dayjs.Dayjs | null, dayjs.Dayjs | null] {
    return [this.dayjsStart, this.dayjsEnd]
  }

  // relationships
  @BelongsTo() resource: Resource
}
