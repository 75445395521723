import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import {
  Attr,
  BelongsTo,
  HasMany,
  HasOne,
  Meta,
} from '@anny.co/vue-jsonapi-orm'
import { Queue } from '@/shared/jsonapi-orm/queues/Queue'
import { Booking } from '@/shared/jsonapi-orm/bookingbuddy/Booking'
import { QueueAgent } from '@/shared/jsonapi-orm/queues/QueueAgent'
import { Activity } from '@/shared/jsonapi-orm/common/Activity'
import dayjs, { Dayjs } from 'dayjs'
import VueI18n from 'vue-i18n'
import { getLocalizedStringFromSeconds } from '@/shared/utils/datetime/duration'
import { QueueCounter } from '@/shared/jsonapi-orm/queues/QueueCounter'
import { QueueTerminal } from '@/shared/jsonapi-orm/queues/QueueTerminal'

export enum QueueTicketStatus {
  WAITING = 'waiting',
  PULLED = 'pulled',
  IN_PROGRESS = 'in_progress',
  PARKED = 'parked',
  CLOSED = 'closed',
  CANCELLED = 'cancelled',
  NOT_APPEARED = 'not_appeared',
}

export class PublicQueueTicket extends ApiResource {
  static jsonApiType = 'queue-tickets'

  @Attr() number: string | null
  @Attr() position: number
  @Attr() estimatedProcessingAt: string
  @Attr() plannedStartDate: string
  @Attr() plannedEndDate: string
  @Attr() processingDuration: number
  @Attr() status: QueueTicketStatus
  @Attr() notifyCustomer: boolean
  @Attr() customerNotificationChannels: string[]
  @Attr() createdAt: string

  @Meta() mobileNotificationsEnabled?: boolean
  @Meta() mobile?: string
  @Meta() notificationViaSms?: boolean
  @Meta() notificationViaWhatsapp?: boolean
  @Meta() forceNotification?: boolean

  @BelongsTo() queue?: Queue
  @BelongsTo() booking?: Booking
  @BelongsTo() counter?: QueueCounter
  @BelongsTo() creatingTerminal?: QueueTerminal
  @BelongsTo() scanningTerminal?: QueueTerminal
  @HasOne() agent?: QueueAgent
  @HasMany() activities?: Activity[]

  /* getters */

  // remaining waiting time
  get remainingWaitingMinutes(): number {
    const diff = dayjs(this.estimatedProcessingAt).diff(dayjs(), 'minute')

    // if diff is negative return -
    if (diff < 0) {
      return 0
    }

    return diff
  }
  getRemainingWaitingTimeString(i18n: VueI18n, uxNow: Dayjs): string {
    // if not waiting return -
    if (this.status !== QueueTicketStatus.WAITING) {
      return '-'
    }

    let diff = dayjs(this.estimatedProcessingAt).diff(uxNow, 'minute')

    // if diff is negative return -
    if (diff < 0) {
      diff = 0
    }

    return getLocalizedStringFromSeconds((40 + diff) * 60, i18n)
  }

  get waitingProgress(): number {
    const start = dayjs(this.createdAt)
    const end = dayjs(this.estimatedProcessingAt)
    const now = dayjs()
    const diff = end.diff(start, 'minute')
    const diffNow = now.diff(start, 'minute')
    // if more time is passed than estimated, return 100
    return diffNow >= diff ? 100 : (diffNow / diff) * 100
  }

  async updateCustomerNotificationChannels(
    enabled = true,
    channels: string[],
    mobile: string,
    accessToken?: string
  ) {
    const query = {
      access_token: accessToken,
    }

    const response = await this.api()
      .include('booking.customer')
      .request('update-notification', 'POST', {
        data: {
          enabled,
          channels,
          mobile,
        },
        params: query,
      })

    this.apiService.updateFromResponse(response.data)
  }

  async cancel(accessToken?: string) {
    const response = await this.api()
      .include('booking.customer')
      .request('cancel', 'POST', {
        data: {},
        params: {
          access_token: accessToken,
        },
      })

    this.apiService.updateFromResponse(response.data)
  }
}

export class QueueTicket extends PublicQueueTicket {
  static jsonApiType = 'queue-tickets'

  @Attr() queuedAt: string
  @Attr() plannedStartDate: string
  @Attr() plannedEndDate: string
  @Attr() delayedUntil: string
  @Attr() notes: string
  @Attr() priority: number
  @Attr() priorityBoost: number
  @Attr() waitingDuration: number
  @Attr() pulledAt: string
  @Attr() closedAt: string
  @Attr() updatedAt: string
}
