import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6675ab19 = () => interopDefault(import('../pages/panel-error.vue' /* webpackChunkName: "pages/panel-error" */))
const _1a936178 = () => interopDefault(import('../pages/sentry/index.vue' /* webpackChunkName: "pages/sentry/index" */))
const _535ef7d6 = () => interopDefault(import('../pages/_panelId.vue' /* webpackChunkName: "pages/_panelId" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/panel-error",
    component: _6675ab19,
    name: "panel-error___de___default"
  }, {
    path: "/sentry",
    component: _1a936178,
    name: "sentry___de___default"
  }, {
    path: "/cs/panel-error",
    component: _6675ab19,
    name: "panel-error___cs"
  }, {
    path: "/cs/sentry",
    component: _1a936178,
    name: "sentry___cs"
  }, {
    path: "/de-formal/panel-error",
    component: _6675ab19,
    name: "panel-error___de-formal"
  }, {
    path: "/de-formal/sentry",
    component: _1a936178,
    name: "sentry___de-formal"
  }, {
    path: "/de/panel-error",
    component: _6675ab19,
    name: "panel-error___de"
  }, {
    path: "/de/sentry",
    component: _1a936178,
    name: "sentry___de"
  }, {
    path: "/en-us/panel-error",
    component: _6675ab19,
    name: "panel-error___en-us"
  }, {
    path: "/en-us/sentry",
    component: _1a936178,
    name: "sentry___en-us"
  }, {
    path: "/en/panel-error",
    component: _6675ab19,
    name: "panel-error___en"
  }, {
    path: "/en/sentry",
    component: _1a936178,
    name: "sentry___en"
  }, {
    path: "/es/panel-error",
    component: _6675ab19,
    name: "panel-error___es"
  }, {
    path: "/es/sentry",
    component: _1a936178,
    name: "sentry___es"
  }, {
    path: "/fr/panel-error",
    component: _6675ab19,
    name: "panel-error___fr"
  }, {
    path: "/fr/sentry",
    component: _1a936178,
    name: "sentry___fr"
  }, {
    path: "/hu/panel-error",
    component: _6675ab19,
    name: "panel-error___hu"
  }, {
    path: "/hu/sentry",
    component: _1a936178,
    name: "sentry___hu"
  }, {
    path: "/it/panel-error",
    component: _6675ab19,
    name: "panel-error___it"
  }, {
    path: "/it/sentry",
    component: _1a936178,
    name: "sentry___it"
  }, {
    path: "/nb/panel-error",
    component: _6675ab19,
    name: "panel-error___nb"
  }, {
    path: "/nb/sentry",
    component: _1a936178,
    name: "sentry___nb"
  }, {
    path: "/nl/panel-error",
    component: _6675ab19,
    name: "panel-error___nl"
  }, {
    path: "/nl/sentry",
    component: _1a936178,
    name: "sentry___nl"
  }, {
    path: "/pl/panel-error",
    component: _6675ab19,
    name: "panel-error___pl"
  }, {
    path: "/pl/sentry",
    component: _1a936178,
    name: "sentry___pl"
  }, {
    path: "/pt/panel-error",
    component: _6675ab19,
    name: "panel-error___pt"
  }, {
    path: "/pt/sentry",
    component: _1a936178,
    name: "sentry___pt"
  }, {
    path: "/ro/panel-error",
    component: _6675ab19,
    name: "panel-error___ro"
  }, {
    path: "/ro/sentry",
    component: _1a936178,
    name: "sentry___ro"
  }, {
    path: "/ru/panel-error",
    component: _6675ab19,
    name: "panel-error___ru"
  }, {
    path: "/ru/sentry",
    component: _1a936178,
    name: "sentry___ru"
  }, {
    path: "/tr/panel-error",
    component: _6675ab19,
    name: "panel-error___tr"
  }, {
    path: "/tr/sentry",
    component: _1a936178,
    name: "sentry___tr"
  }, {
    path: "/ua/panel-error",
    component: _6675ab19,
    name: "panel-error___ua"
  }, {
    path: "/ua/sentry",
    component: _1a936178,
    name: "sentry___ua"
  }, {
    path: "/cs/:panelId?",
    component: _535ef7d6,
    name: "panelId___cs"
  }, {
    path: "/de-formal/:panelId?",
    component: _535ef7d6,
    name: "panelId___de-formal"
  }, {
    path: "/de/:panelId?",
    component: _535ef7d6,
    name: "panelId___de"
  }, {
    path: "/en-us/:panelId?",
    component: _535ef7d6,
    name: "panelId___en-us"
  }, {
    path: "/en/:panelId?",
    component: _535ef7d6,
    name: "panelId___en"
  }, {
    path: "/es/:panelId?",
    component: _535ef7d6,
    name: "panelId___es"
  }, {
    path: "/fr/:panelId?",
    component: _535ef7d6,
    name: "panelId___fr"
  }, {
    path: "/hu/:panelId?",
    component: _535ef7d6,
    name: "panelId___hu"
  }, {
    path: "/it/:panelId?",
    component: _535ef7d6,
    name: "panelId___it"
  }, {
    path: "/nb/:panelId?",
    component: _535ef7d6,
    name: "panelId___nb"
  }, {
    path: "/nl/:panelId?",
    component: _535ef7d6,
    name: "panelId___nl"
  }, {
    path: "/pl/:panelId?",
    component: _535ef7d6,
    name: "panelId___pl"
  }, {
    path: "/pt/:panelId?",
    component: _535ef7d6,
    name: "panelId___pt"
  }, {
    path: "/ro/:panelId?",
    component: _535ef7d6,
    name: "panelId___ro"
  }, {
    path: "/ru/:panelId?",
    component: _535ef7d6,
    name: "panelId___ru"
  }, {
    path: "/tr/:panelId?",
    component: _535ef7d6,
    name: "panelId___tr"
  }, {
    path: "/ua/:panelId?",
    component: _535ef7d6,
    name: "panelId___ua"
  }, {
    path: "/:panelId?",
    component: _535ef7d6,
    name: "panelId___de___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
