import { AxiosInstance, AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import { randomString } from '@/shared/utils/Helpers'
import { UrlBuilder } from '@/shared/types/UrlBuilder'
import { base64_decode, base64_encode } from '@/shared/utils/base64'

export type CodeRequestOptions = {
  clientId?: number | string
  scope?: string
  endpoint: string
  redirectUri: string
  params: Record<string, any>
  loginStatePrefix: string
}

export type TokenRequestOptions = {
  grantType: 'password' | 'refresh_token'
  clientId?: number | string
  clientSecret?: string
  username?: string
  password?: string
  refreshToken?: string
  scope?: string
  saveAccessToken: boolean
}

export type JWTToken = {
  aud: string // audience -> client_id
  jti: string // jwt token id
  iat: number // issued at
  nbf: number // not valid before
  exp: number // expires_at
  sub: string // subject/owner -> user_id
  scopes: string[] // allowed scopes
}

export interface ExchangeAuthorizationCode {
  axiosClient: AxiosInstance
  clientId?: number | string
  endpoint: string
  redirectUri: string
  code: string
  codeVerifier: string
}

export interface RefreshTokenOptions {
  axiosClient: AxiosInstance
  clientId: string
  scope: string
  refreshToken: string
  endpoint: string
}

export interface RevokeTokenOptions {
  axiosClient: AxiosInstance
  token: string
  endpoint: string
}

export interface TokenData {
  accessToken: string
  refreshToken: string
  expiresIn: number
  expiresAt: string
}

export interface RequestCodeData {
  url: string
  codeVerifier: string
  state: string
}

export interface AuthStoragePaths {
  jwtToken: string
  refreshToken: string
  codeVerifier: string
  oauthState: string
  intendedUrl: string
  loginAttemptCount: string
}

export const authStoragePaths = (prefix: string): AuthStoragePaths => {
  return {
    jwtToken: prefix + 'jwt',
    refreshToken: prefix + 'refresh_token',
    codeVerifier: prefix + 'oauth_code_verifier',
    oauthState: prefix + 'oauth_state',
    intendedUrl: prefix + 'intended_url',
    loginAttemptCount: prefix + 'login_attempts',
  }
}

const prefix = process.env.STORAGE_PREFIX || ''
export const JWT_TOKEN_KEY = prefix + 'jwt'
export const REFRESH_TOKEN_KEY = prefix + 'refresh_token'
export const INTENDED_URL_KEY = prefix + 'intended_url'

/**
 *
 * @param axiosClient
 * @param clientId
 * @param endpoint
 * @param redirectUri
 * @param code
 * @param codeVerifier
 */
export async function exchangeCodeForAccessToken({
  axiosClient,
  clientId,
  endpoint,
  redirectUri,
  code,
  codeVerifier,
}: ExchangeAuthorizationCode): Promise<TokenData> {
  const response = await axiosClient.post(endpoint, {
    grant_type: 'authorization_code',
    client_id: clientId,
    redirect_uri: redirectUri,
    code_verifier: codeVerifier,
    code,
  })

  return generateTokenData(response)
}

/**
 * Refresh a new access token.
 *
 * @param axiosClient
 * @param clientId
 * @param refreshToken
 * @param scope
 * @param endpoint
 */
export async function requestRefreshToken({
  axiosClient,
  clientId,
  refreshToken,
  scope = '',
  endpoint,
}: RefreshTokenOptions): Promise<TokenData> {
  const response = await axiosClient.post(endpoint, {
    grant_type: 'refresh_token',
    client_id: clientId,
    // client_secret: '',
    scope,
    refresh_token: refreshToken,
  })

  return generateTokenData(response)
}

/**
 * Revokes a token.
 * @param axiosClient
 * @param token
 * @param endpoint
 */
export async function revokeToken({
  axiosClient,
  token,
  endpoint,
}: RevokeTokenOptions): Promise<void> {
  const decodedToken = parseJwt(token)

  await axiosClient.delete(endpoint + '/' + decodedToken['jti'], {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

/**
 * Parse a jwt token to json.
 * @param token
 */
export function parseJwt(token: string): any {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    base64_decode(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

export async function createRequestAuthorizationCodeData({
  clientId,
  scope,
  endpoint,
  redirectUri,
  params = {}, // additional query params
  // purpose: referencing embeded state
  loginStatePrefix = '', // ends with -
}: CodeRequestOptions): Promise<RequestCodeData> {
  // Create code verifier
  const oauthState = loginStatePrefix + randomString(12)
  const codeVerifier = randomString(128)
  const codeChallenge = await generateCodeChallenge(codeVerifier)

  // Build url
  const builder = new UrlBuilder({
    baseUrl: endpoint,
    path: '',
    queryParams: {
      ...params,
      client_id: clientId,
      redirect_uri: redirectUri,
      response_type: 'code',
      scope,
      state: oauthState,
      code_challenge: codeChallenge,
      code_challenge_method: 'S256',
    },
    snakeParams: false,
  })

  return {
    url: builder.url,
    codeVerifier,
    state: oauthState,
  } as RequestCodeData
}

/**
 *
 * @param codeVerifier
 */
export async function generateCodeChallenge(codeVerifier: string) {
  let digest = undefined as any
  if (process.server) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    digest = require('crypto')
      .createHash('sha256')
      .update(codeVerifier)
      .digest()
  } else {
    digest = await crypto.subtle.digest(
      'SHA-256',
      new TextEncoder().encode(codeVerifier)
    )
  }

  return base64_encode(String.fromCharCode(...new Uint8Array(digest)))
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
}

/**
 * Create token data object from response.
 * @param response
 */
function generateTokenData(response: AxiosResponse): TokenData {
  return {
    accessToken: response.data.access_token,
    refreshToken: response.data.refresh_token,
    expiresIn: response.data.expires_in,
    expiresAt: dayjs().add(response.data.expires_in, 'minutes').toISOString(),
  } as TokenData
}
