import { Attr, BelongsTo } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { Organization } from '@/shared/jsonapi-orm/bookingbuddy/Organization'
import { User } from '@/shared/jsonapi-orm/bookingbuddy/User'

export class OrganizationUser extends ApiResource {
  static jsonApiType = 'organization-users'
  @BelongsTo() user: User
  @BelongsTo() organization: Organization
  @Attr() isOwner: boolean
  @Attr() createdAt: string
}
