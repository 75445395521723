import { Context, Plugin } from '@nuxt/types'
import { Inject } from '@nuxt/types/app'
import VueI18n from 'vue-i18n'
import {
  getLocalizedStringFromSeconds,
  getLocalizedStringFromUnit,
} from '@/shared/utils/datetime/duration'
import dayjs from 'dayjs'
import { Store } from 'vuex'

// Define formatter types
type CurrencyCode = 'EUR' | 'USD' | string
type CurrencyFormatter = (value: number, currencyCode: CurrencyCode) => string
type DecimalFormatter = (value: number) => string
type PercentFormatter = (value: number) => string
type DurationFormatter = (
  value: number,
  trimOne?: boolean,
  unit?: string
) => string
type DateFormatter = (value: string, format?: string) => string
type DateTimeFormatter = (value: string) => string
type FromNowFormatter = (value: string) => string

// Define formatters interface
export interface Formatters {
  currency: CurrencyFormatter
  accountingCurrency: CurrencyFormatter
  decimal: DecimalFormatter
  percent: PercentFormatter
  duration: DurationFormatter
  date: DateFormatter
  datetime: DateTimeFormatter
  fromNow: FromNowFormatter
}

/*
 * Factories
 * Here are the factory functions which create
 * the formatter functions.
 */

export const currencyFormatterFactory = (
  i18n: VueI18n,
  showFreeText = true
): CurrencyFormatter => {
  return (value: number, currencyCode: CurrencyCode) => {
    if (value !== 0 || !showFreeText) {
      return i18n.n(value, {
        style: 'currency',
        currency: currencyCode,
      }) as string
    } else {
      return i18n.t('common.filters.currency.free') as string
    }
  }
}

export const decimalFormatterFactory = (i18n: VueI18n): DecimalFormatter => {
  return (value: number) => {
    return i18n.n(value, { style: 'decimal' }) as string
  }
}

export const percentFormatterFactory = (i18n: VueI18n): PercentFormatter => {
  return (value: number) => {
    // value has to be between 0 and 1
    if (value > 1) {
      value = value / 100
    }
    return i18n.n(value, { style: 'percent', maximumFractionDigits: 2 })
  }
}

export const durationFormatterFactory = (i18n: VueI18n): DurationFormatter => {
  return (value: number, trimOne = false, unit?: string) => {
    if (unit) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return getLocalizedStringFromUnit(value, unit, i18n, false, trimOne)
    }
    return getLocalizedStringFromSeconds(value * 60, i18n, trimOne)
  }
}

export const dateFormatterFactory = (): DateFormatter => {
  return (value: string, format = 'L') => {
    return dayjs(value).format(format)
  }
}

export const dateTimeFormatterFactory = (): DateTimeFormatter => {
  return (value: string) => {
    return dayjs(value).format('L LT')
  }
}

export const fromNowFormatterFactory = (
  store: Store<any>
): FromNowFormatter => {
  return (value: string) => {
    const now = store.getters['ux/now'] || new Date()
    return dayjs(value).from(now)
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $formatters: Formatters
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$formatters inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $formatters: Formatters
  }
  // nuxtContext.$formatters
  interface Context {
    $formatters: Formatters
  }
}

const formattersPlugin: Plugin = (ctx: Context, inject: Inject) => {
  inject('formatters', {
    currency: currencyFormatterFactory(ctx.app.i18n),
    decimal: decimalFormatterFactory(ctx.app.i18n),
    percent: percentFormatterFactory(ctx.app.i18n),
    duration: durationFormatterFactory(ctx.app.i18n),
    date: dateFormatterFactory(),
    datetime: dateTimeFormatterFactory(),
    fromNow: fromNowFormatterFactory(ctx.store),
    accountingCurrency: currencyFormatterFactory(ctx.app.i18n, false),
  } as Formatters)
}

export default formattersPlugin
