import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { Attr, BelongsTo, HasMany, Meta } from '@anny.co/vue-jsonapi-orm'
import { Organization } from '@/shared/jsonapi-orm/bookingbuddy/Organization'
import { QueueTicket } from '@/shared/jsonapi-orm/queues/QueueTicket'
import { QueueAgent } from '@/shared/jsonapi-orm/queues/QueueAgent'
import { QueueRule } from '@/shared/jsonapi-orm/queues/QueueRule'

export enum QueueStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  ON_HOLD = 'on_hold',
}

export class Queue extends ApiResource {
  static jsonApiType = 'queues'

  @Attr() name: string
  @Attr(QueueStatus.ACTIVE) status: QueueStatus
  @Attr() isDefault: boolean
  @Attr('users-line') icon: string
  @Attr('#5fbbd0') color: string
  @Attr() createdAt: string
  @Attr() updatedAt: string

  @Meta() agentsCount?: number
  @Meta() activeAgentsCount?: number

  @BelongsTo() organization?: Organization
  @HasMany() tickets?: QueueTicket[]
  @HasMany() agents?: QueueAgent[]
  @HasMany() rules?: QueueRule[]
}
