import {
  Attr,
  BelongsTo,
  HasMany,
  HasOne,
  Meta,
} from '@anny.co/vue-jsonapi-orm'
import { Service, ServiceRecurringFrequencies } from './Service'
import { ApiResource } from '../ApiResource'
import { Resource } from '@/shared/jsonapi-orm/bookingbuddy/Resource'
import {
  CustomEntry,
  CustomEntryMap,
} from '@/shared/jsonapi-orm/custom-fields/CustomEntry'
import { Activity } from '@/shared/jsonapi-orm/common/Activity'
import { ExternalCalendar } from '@/shared/jsonapi-orm/bookingbuddy/ExternalCalendar'
import { BookingAddOn } from '@/shared/jsonapi-orm/bookingbuddy/BookingAddOn'
import { Order } from '@/shared/jsonapi-orm/bookingbuddy/Order'
import { CancellationPolicy } from '@/shared/jsonapi-orm/bookingbuddy/CancellationPolicy'
import dayjs from 'dayjs'
import { QueryBuilder } from '@anny.co/vue-jsonapi-orm/dist/builder/QueryBuilder'
import { TestResult } from '@/shared/jsonapi-orm/tests/TestResult'
import { BookingPreview } from '@/shared/types/bookings/BookingPreview'
import { ModelIdentifier } from '@/shared/jsonapi-orm/bookingbuddy/ModelIdentifier'
import { Customer } from '@/shared/jsonapi-orm/bookingbuddy/Customer'
import { Reminder } from './Reminder'
import { Comment } from '@/shared/jsonapi-orm/bookingbuddy/Comment'
import { QueueTicket } from '@/shared/jsonapi-orm/queues/QueueTicket'
import { Organization } from '@/shared/jsonapi-orm/bookingbuddy/Organization'
import { BookingSeries } from '@/shared/jsonapi-orm/bookingbuddy/BookingSeries'
import { AvailabilityInterval } from '@/shared/types/bookings/AvailabilityInterval'
import { BookingParticipant } from '@/shared/jsonapi-orm/bookingbuddy/BookingParticipant'
import { File } from '@/shared/jsonapi-orm/bookingbuddy/File'

export enum BookingStatus {
  ACCEPTED = 'accepted',
  INVITED = 'invited',
  REQUESTED = 'requested',
  REJECTED = 'rejected',
  RESERVED = 'reserved',
  EXPIRED = 'expired',
  CANCELED = 'canceled',
}

export type BookingSeriesType = 'series' | 'recurring'

export type BookingAddOnParams = {
  booking_id?: string
  add_on_id: string
  quantity: number
}

export type SubBookingParams = {
  booking_id?: string
  service_id: string
  resource_id: string
  quantity: number
}

export const getDefaultRecurringParams = () => ({
  duration: null as number | null,
  until: null as string | null,
  interval: 1,
  frequency: ServiceRecurringFrequencies.WEEKLY as ServiceRecurringFrequencies,
  days: [] as string[] | number[] | null,
  month_day: null as number | null,
  week_start: null as string | null,
  set_pos: null as string | null,
})

export type RecurringSettings = ReturnType<typeof getDefaultRecurringParams>
export type RecurringOptions = {
  meta: {
    available: number
    total: number
  }
  intervals: AvailabilityInterval[]
}

export const bookingCalculationFactory = (): BookingCalculation => ({
  total: 0,
  subtotal: 0,
  net_amount: 0,
  tax_amount: 0,
  gross_amount: 0,
  currency: 'EUR',
  booking_add_ons: [],
  sub_bookings: [],
  resource: null,
  service: null,
  start_date: null,
  end_date: null,
  unit: 'minute',
  charged_duration: 0,
  quantity: 1,
  price_hidden: false,
  is_net_price: false,
  booking_series: null,
})

export type SubBookingCalculationData = {
  total: number
  currency?: string
  service: { id: string; name: string }
  resource: { id: string; name: string }
}

export type AddOnCalculationData = {
  add_on: { id: string; name: string }
  total: number
  quantity: number
  currency?: string
}

export interface BookingCalculationData {
  unit: string
  currency: string
  price_hidden: boolean
  tax_rate: number
  is_net_price: boolean
  charged_duration: number
  resource: Resource | null
  booking_add_ons: AddOnCalculationData[]
  sub_bookings: SubBookingCalculationData[]
  quantity: number
}

export interface BookingsCalculation {
  total: number
  gross_amount: number
  is_net_price: boolean
  net_amount: number
  tax_amount: number
  currency: string
  price_hidden: boolean
  bookings: BookingCalculation[]
}

export interface BookingCalculation {
  total: number
  subtotal: number
  net_amount?: number
  tax_amount?: number
  gross_amount?: number
  booking_add_ons: AddOnCalculationData[]
  sub_bookings: SubBookingCalculationData[]
  resource: {
    id: string
    name: string
  } | null
  service: {
    id: string
    name: string
    is_rental_option: boolean
    is_series?: boolean
  } | null
  start_date: string | null
  end_date: string | null
  unit: string
  charged_duration: number
  currency: string
  quantity: number
  price_hidden: boolean
  is_net_price: boolean
  booking_series: BookingSeries | null
}

export class PublicBooking extends ApiResource {
  static jsonApiType = 'bookings'
  @Attr() number: string
  @Attr(BookingStatus.ACCEPTED) status: BookingStatus
  @Attr(false) isBlocker: boolean
  @Attr() is_blocker: boolean
  @Attr() startDate: string
  @Attr() start_date: string
  @Attr() endDate: string
  @Attr() end_date: string
  @Attr() blockerStartDate: string
  @Attr() code?: string
  @Attr() blocker_start_date: string
  @Attr() blockerEndDate: string
  @Attr() blocker_end_date: string
  @Attr('') description: string
  @Attr() chargedDuration: number
  @Attr(1) weight: number
  @Attr(false) isSequence: boolean
  @Attr(false) priceHidden: boolean
  @Attr(null) checkInDate: string | null
  @Attr(null) checkOutDate: string | null
  @Attr() seriesUuid: string | null
  @Attr() isSeries: boolean
  @Attr() isSeriesMaster: boolean
  @Attr() seriesId: string | null
  @Attr() seriesType: BookingSeriesType | null
  @Attr('EUR') currency: string
  @Attr() total: number
  @Attr(false) isNetTotal: boolean
  @Attr() subtotal: number
  // relations
  @BelongsTo() organization?: Organization
  @BelongsTo() sequence?: Booking
  @BelongsTo() resource?: Resource
  @BelongsTo() service?: Service | null
  @BelongsTo() cancellationPolicy?: CancellationPolicy | null
  @HasMany() subBookings?: this[]
  @HasMany() bookingAddOns?: BookingAddOn[]
  @HasMany() reminders?: Reminder[]
  @HasMany() testResults?: TestResult[]
  @HasMany() sequencedBookings?: Booking[]
  @HasMany() seriesBookings?: Booking[]
  @BelongsTo() seriesMaster?: Booking
  @HasOne() queueTicket?: QueueTicket
  @HasOne() bookingSeries?: BookingSeries
  @BelongsTo() host?: BookingParticipant | null
  @HasMany() attendees?: BookingParticipant[]
  @HasMany() files?: File[]

  // meta
  @Meta() personalizationName?: string | null
  // string containing all service names and quantities of a sequence
  @Meta() sequencedServicesLabel?: string | null
  @Meta() queueTicketUuid?: string | null
  @Meta() zoom?: boolean
  @Meta() zoomJoinUrl?: string
  @Meta() zoomMeetingId?: string
  @Meta() zoomMeetingPassword?: string
  @Meta() zoomRawUrl?: string

  @Meta() cwaAvailable?: boolean | null
  @Meta() cwaLinks?: string[] | null
  @Meta() cwaAcceptedAt?: string | null
  @Meta() cwaChoice?: number | null

  @Meta() lucaAvailable?: boolean | null
  @Meta() lucaJwt?: string | null

  @Meta() teams?: boolean
  @Meta() teamsUuid?: string
  @Meta() teamsUrl?: string
  @Meta() email?: string

  /* Helpers */
  get groupedSubBookings():
    | { resource: Resource; service: Service; quantity: number, key: string }[]
    | undefined {
    if (!this.subBookings) {
      return undefined
    }
    // group and count sub bookings by resource and service
    const grouped = this.subBookings.reduce((acc, subBooking) => {
      const key = `${subBooking.resource?.id}-${subBooking.service?.id}`
      if (acc[key]) {
        acc[key].quantity++
      } else {
        acc[key] = {
          key,
          resource: subBooking.resource!,
          service: subBooking.service!,
          quantity: 1,
        }
      }
      return acc
    }, {} as Record<string, { resource: Resource; service: Service; quantity: number }>)
    return Object.values(grouped)
  }

  get isReserved(): boolean {
    return this.status === BookingStatus.RESERVED
  }

  get isInvited(): boolean {
    return this.status === BookingStatus.INVITED
  }

  get isAccepted(): boolean {
    return this.status === BookingStatus.ACCEPTED
  }

  /**
   * Check if resource uses check-in.
   */
  get checkInEnabled(): boolean {
    if (this.isBlocker) return false
    return this.resource?.metaSettings?.checkIn?.isEnabled ?? true
  }

  /**
   * Check if resource allows multiple check-ins.
   */
  get multipleCheckinsAllowed(): boolean {
    if (this.isBlocker) return false
    return this.resource?.metaSettings?.checkIn?.allowMultiple ?? false
  }

  get selfCheckInEnabled(): boolean {
    return this.resource?.metaSettings?.selfCheckIn?.isEnabled ?? false
  }

  /**
   * Check if self check-out is enabled
   *
   * @return {{}}
   */
  get selfCheckOutEnabled(): boolean {
    return this.resource?.metaSettings?.selfCheckIn?.checkOutEnabled ?? true
  }

  get checkedIn(): boolean {
    return !!this.checkInDate
  }

  /**
   * check if booking is already checked out
   * @return {boolean}
   */
  get checkedOut(): boolean {
    return !!this.checkOutDate
  }

  /**
   * Check if check in need geolocation
   * @return {*}
   */
  get checkInNeedsGeoLocation(): boolean {
    return this.resource?.metaSettings?.selfCheckIn?.geoCheckEnabled ?? false
  }

  /**
   * Return check in start date time
   * @return {dayjs.Dayjs}
   */
  get checkInStartDateTime(): dayjs.Dayjs {
    return dayjs(this.startDate).subtract(
      this.resource?.metaSettings?.selfCheckIn?.disabledUntil ?? 0,
      'minute'
    )
  }

  get hasTotal(): boolean {
    return !Number.isNaN(this.total)
  }

  /**
   * Cancel booking with order access token
   * @param accessToken
   * @param seriesCancellation
   */
  async cancelWithToken(
    accessToken: string,
    seriesCancellation: 'entire' | 'following' | null = null
  ) {
    try {
      const response = await this.api()
        .query({
          access_token: accessToken,
          strategy: seriesCancellation,
        })
        .request('cancel')

      Booking.resourcesFromResponse(response.data, this.apiService)
      return this
    } catch (e) {
      console.log(e)
      return false
    }
  }

  /**
   * Check booking in or out with order access token and optional location
   * @param type
   * @param accessToken
   * @param location
   */
  async checkInWithToken(
    accessToken: string,
    type: 'check-in' | 'check-out' = 'check-in',
    location?: { latitude?: number; longitude?: number }
  ) {
    try {
      const response = await this.api()
        .query({ access_token: accessToken })
        .request(type, 'post', {
          data: { ...location },
        })
      this.apiService.updateFromResponse(response.data)
      return this
    } catch (e) {
      console.log(e)
      return false
    }
  }

  /**
   * Check booking in or out
   * @param type
   */
  async checkIn(type: 'check-in' | 'check-out' = 'check-in') {
    try {
      this.$isLoading = true
      const response = await this.api().request(type, 'post', {
        data: {},
      })
      this.apiService.updateFromResponse(response.data)
      return this
    } catch (e) {
      console.log(e)
      return false
    } finally {
      this.$isLoading = false
    }
  }
}

export class Booking extends PublicBooking {
  // admin and customer fields
  @Attr() chargedTotal: number
  @Attr() chargedSubtotal: number
  @Attr() startingFee: number
  @Attr() customerNote: string | null
  @Attr() updatedAt: string
  @Attr() createdAt: string
  @Attr() canceledAt: string | null

  @Attr({}) customEntryMap: CustomEntryMap
  // admin fields
  @Attr() note?: string | null
  @Attr() externalUuid?: string | null
  @Attr(true) manuallyCreated?: boolean
  @Attr(false) isSubBooking?: boolean
  @Attr() hasPayment?: boolean
  @Attr() isEditable?: boolean
  // relations
  @HasMany() activities?: Activity[]
  @HasMany() modelIdentifiers?: ModelIdentifier[]
  @HasMany() customEntries?: CustomEntry[]
  @HasMany() comments?: Comment[]
  @BelongsTo() externalCalendar?: ExternalCalendar | null
  @BelongsTo() order?: Order | null
  @BelongsTo() customer?: Customer | null
  @BelongsTo() superBooking?: this | null

  @Meta() zoomStartUrl?: string
  @Meta() dornerLabPossible?: boolean

  /**
   * Get preview data for booking.
   */
  getPreviewData(): BookingPreview {
    const preview: BookingPreview = {
      id: this.id,
      status: this.status,
      startDate: this.startDate,
      endDate: this.endDate,
      description: this.description,
      weight: this.weight,
      isBlocker: this.isBlocker,
    }
    if (this.resource) {
      preview.resource = this.resource.getPreviewData()
    }
    if (this.service) {
      preview.service = {
        id: this.service.id,
        name: this.service.name,
        minDuration: this.service.minDuration,
        maxDuration: this.service.maxDuration,
        hidden: this.service.hidden,
        isFullDay: this.service.isFullDay,
      }
    }
    if (this.order?.customer) {
      preview.order = {
        id: this.order.id,
        customer: {
          id: this.order.customer.id,
          fullName: this.order.customer.fullName,
          givenName: this.order.customer.givenName,
          familyName: this.order.customer.familyName,
        },
      }
    }
    if (this.customer) {
      preview.customer = {
        id: this.customer.id,
        fullName: this.customer.fullName,
        givenName: this.customer.givenName,
        familyName: this.customer.familyName,
      }
    }

    return preview
  }

  getMonth(): string {
    return dayjs(this.startDate).format('MMMM')
  }

  async cancel(): Promise<void> {
    const { data } = await this.api().request('cancel')

    Booking.resourcesFromResponse(data, this.apiService)
  }

  async duplicate(): Promise<Booking> {
    const { data } = await this.api().request('duplicate')
    return Booking.resourceFromResponse(data, this.apiService).data
  }

  getLabel(): string {
    if (this.isSequence && this.sequencedServicesLabel) {
      return this.sequencedServicesLabel
    } else if (this.service && !this.service.isRentalOption) {
      return this.service.name
    } else if (this.resource?.parent) {
      return this.resource.parent.name + ' - ' + this.resource.name ?? '-'
    } else if (this.resource) {
      return this.resource.name ?? '-'
    } else {
      return this.description
    }
  }

  /**
   * Precalculate booking.
   */
  async calculate(quantity = 1): Promise<boolean> {
    if (this.resource && this.startDate && this.endDate) {
      const payload = {
        resource_id: this.resource.id,
        service_id: this.service?.id ?? null,
        start_date: this.startDate,
        end_date: this.endDate,
        booking_add_ons:
          this.bookingAddOns?.map((ba) => ({
            add_on_id: ba.addOn!.id,
            quantity: ba.quantity,
          })) ?? [],
        sub_bookings: this.subBookings?.map((sb) => ({
          service_id: sb.service?.id ?? null,
          resource_id: sb.resource?.id ?? null,
        })),
        quantity: quantity,
      }

      try {
        this.$isLoading = true
        const { data }: { data: BookingCalculation } = await new QueryBuilder(
          this.$staticThis.apiPath,
          this.axiosClient,
          this.apiService
        ).request('order/bookings/calculate', 'POST', {
          data: payload,
        })
        this.$trackChanges = false
        this.total = data.total
        this.subtotal = data.subtotal
        this.$trackChanges = true
        return true
      } catch (e) {
        console.log(e)
      } finally {
        this.$isLoading = false
      }
    }
    return false
  }
}
