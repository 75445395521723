/**
 * Transform any string to a valid slug
 * @param text
 * @returns {string}
 */
function slugify(text) {
  if (!text) return ''
  return text
    .toString()
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/ä/, 'ae')
    .replace(/ö/, 'oe')
    .replace(/ü/, 'ue')
    .replace(/ß/, 'ss')
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple -- with single -
}

function trim(text) {
  return text
    .toString()
    .replace(/^-+|-+$/g, '') // remove leading, trailing -
    .trim()
}

/**
 * Check if an object is empty
 * @param obj
 * @returns {boolean}
 */
function isEmptyObject(obj) {
  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) return false
  }
  return true
}

/**
 * Generate random string
 * @param length
 * @param characters
 * type {function(*=, *=): string}
 */
const randomString = (
  length = 10,
  characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
) => {
  let result = ''
  let charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

/**
 * Hash a string
 * @see https://stackoverflow.com/questions/18338890/are-there-any-sha-256-javascript-implementations-that-are-generally-considered-t
 * @param message
 * @return {Promise<string>}
 */
export async function sha256(message) {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message)

  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer)

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer))

  // convert bytes to hex string
  const hashHex = hashArray
    .map((b) => ('00' + b.toString(16)).slice(-2))
    .join('')
  return hashHex
}

/**
 * Deep clone object or array
 * @param obj
 * @return {any}
 */
function clone(obj) {
  if (!obj) {
    return obj
  }
  return JSON.parse(JSON.stringify(obj))
}

/**
 * Url encode object of query params
 * @param data
 * @return {string}
 */
function encodeQueryData(data) {
  const ret = []
  for (let d in data)
    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]))
  return ret.join('&')
}

export { trim, slugify, isEmptyObject, randomString, clone, encodeQueryData }
