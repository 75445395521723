import { getField, updateField } from 'vuex-map-fields'
import lightStyles from '../assets/sass/light-theme.module.scss'
import darkStyles from '../assets/sass/dark-theme.module.scss'
import { ActionTree, GetterTree, MutationTree, StoreOptions } from 'vuex'
import Bowser from 'bowser'
import Parser = Bowser.Parser.Parser
import { Component } from 'vue'
import { transform } from 'lodash'

import Vue from 'vue'
import { Route } from 'vue-router'
import { ActionDefinition } from '@/shared/types/DataTable'
import { TranslateResult } from 'vue-i18n'
import dayjs from 'dayjs'

type ColorTheme = 'light' | 'dark'

type Modal = {
  id: string
  close: () => void
}

type AlertAction = {
  name: string | TranslateResult
  handler: () => void
}

export type Alert = {
  show: boolean
  message?: string | TranslateResult
  cancelable?: boolean
  destructive?: boolean
  proceedText?: string | TranslateResult
  cancelText?: string | TranslateResult
  proceedAction?: () => void
  cancelAction?: () => void
  actions?: AlertAction[]
}

type CustomModal = {
  show: boolean
  component?: Component
  attrs?: { [key: string]: any }
  listeners?: { [key: string]: () => void }
}

export type ActionsModal = {
  show: boolean
  actions?: ActionDefinition[]
}

export type Notification = {
  type: 'default' | 'danger' | 'success'
  html: string
  id?: string
  timeout?: number
  action?: {
    label: string
    icon?: Array<string>
    handler: () => void
  }
  persistent?: boolean
}

export type StatusBarMessage = {
  height: number
  type: 'default' | 'error' | 'warning' | 'success'
  message: string // may contain html
  action: {
    handler: () => void
    text: string
  } | null
  route: Route | null
  fixed: boolean

  closeCallback: (() => void) | null
}

export type CookieSettings = {
  accepted: boolean
  gtag: boolean
  options: string[]
}

export type UXState = {
  now: Date
  alert: Alert
  customModal: CustomModal
  actionsModal: ActionsModal
  colorTheme: ColorTheme
  detectColorTheme: boolean
  styles: { [key: string]: string }
  customCssVariables: { [key: string]: string }
  notifications: Notification[]
  isPwa: boolean
  isEmbedded: boolean
  footerSpace: number
  footerHeight: number
  bowser: Parser | null // https://github.com/lancedikson/bowser
  isMobile: boolean
  isTablet: boolean
  isTouch: boolean
  isSafari: boolean
  sidebar: {
    hidden: boolean // show or hide sidebar
    overlay: boolean // overlay sidebar on content or push content
    width: number
    iconWidth: number
  }
  statusBar: StatusBarMessage
  topbar: {
    height: number
    hidden: boolean
  }
  bottomBar: {
    enabled: boolean
    height: number
    hidden: boolean
    hiddenMobile?: boolean
  }
  timezone: string | null
  // UTC Date - locale Date => time offset in minutes
  // https://medium.com/@toastui/handling-time-zone-in-javascript-547e67aa842d#8db3
  timeZoneOffset: number | null
  windowSize: string
  timerIsRunning: boolean
  cookieSettings: CookieSettings
  localizationLocale: string | null
  // handle modal stack
  modals: Modal[]
}

type UXStoreOptions<S> = {
  state: S
  getters?: GetterTree<UXState & S, any>
  mutations?: MutationTree<UXState & S>
  actions?: ActionTree<UXState & S, any>
}

interface UXStore<S> extends StoreOptions<S> {
  state: () => S
}

export default function <S extends Record<string, any>>(
  options: UXStoreOptions<S>
) {
  const uxStore: UXStore<UXState & S> = {
    state: () => ({
      now: new Date(),
      alert: {
        show: false,
      },
      customModal: {
        show: false,
      },
      actionsModal: {
        show: false,
      },
      colorTheme: 'light', // (light|dark)
      detectColorTheme: true,
      styles: lightStyles,
      customCssVariables: {},
      notifications: [],
      isPwa: false,
      isEmbedded: false,
      footerSpace: 0,
      footerHeight: 200,
      bowser: null,
      isMobile: false,
      isTablet: false,
      isTouch: false,
      isSafari: false,
      sidebar: {
        hidden: true,
        overlay: false,
        width: 0,
        iconWidth: 60,
      },
      statusBar: {
        height: 0,
        type: 'default',
        message: '',
        route: null,
        action: null,
        fixed: true,
        closeCallback: null,
      },
      topbar: {
        height: 0,
        hidden: true,
      },
      bottomBar: {
        enabled: true,
        height: 0,
        hidden: true,
      },
      timezone: null,
      timeZoneOffset: null,
      windowSize: '',
      timerIsRunning: false,
      cookieSettings: {
        accepted: false,
        gtag: true,
        options: [],
      },
      localizationLocale: null,
      modals: [],
      ...options.state,
    }),
    // getters
    getters: {
      getField,
      /**
       * Get preferred color theme from browser or system settings
       */
      getPreferredColorScheme: () => {
        if (typeof window === 'undefined') {
          return 'light'
        }

        if (window && window.matchMedia) {
          const isDarkMode = window.matchMedia(
            '(prefers-color-scheme: dark)'
          ).matches
          const isLightMode = window.matchMedia(
            '(prefers-color-scheme: light)'
          ).matches
          const isNotSpecified = window.matchMedia(
            '(prefers-color-scheme: no-preference)'
          ).matches
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const hasNoSupport = !isDarkMode && !isLightMode && !isNotSpecified
          if (isDarkMode) {
            return 'dark'
          }
        }
        return 'light'
      },
      /**
       * Transform styles into css custom properties
       */
      getColorThemeStyles: (state) => {
        return transform(state.styles, function (result, val, key) {
          const cssKey =
            '--' + key.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`)
          result[cssKey] = val
        })
      },
      cookiesAccepted: (state) => {
        return state.cookieSettings?.accepted ?? false
      },
      getCustomGlobalCss: (state) => {
        if (Object.keys(state.customCssVariables).length === 0) {
          return ''
        }
        let str = '* {'
        Object.entries(state.customCssVariables).forEach(([key, value]) => {
          str += `${key}: ${value};`
        })
        str += '}'
        return str
      },
      ...options.getters,
    },
    // mutations
    mutations: {
      updateField,
      /**
       * Display an Alert
       * @param state
       * @param alert
       */
      showAlert: (state, alert) => {
        state.alert = {
          ...alert,
          show: true,
        }
      },
      /**
       * Hide an Alert
       * @param state
       */
      closeAlert: (state) => {
        state.alert = {
          show: false,
        }
      },
      /**
       * Show a model with custom component
       * @param state
       * @param component
       * @param attrs
       * @param listeners
       */
      showCustomModal: (state, { component, attrs, listeners }) => {
        state.customModal.show = false
        state.customModal.component = component
        state.customModal.attrs = attrs
        state.customModal.listeners = listeners
        state.customModal.show = true
      },
      /**
       * Hide custom modal and reset data
       * @param state
       */
      hideCustomModal: (state) => {
        state.customModal.show = false
        delete state.customModal.component
        delete state.customModal.attrs
        delete state.customModal.listeners
      },
      /**
       * Show model with an array of actions
       * @param state
       * @param actions
       */
      showActionsModal: (state, actions: ActionDefinition[]) => {
        Vue.set(state.actionsModal, 'actions', actions)
        Vue.set(state.actionsModal, 'show', true)
        // state.actionsModal.actions = actions
        state.actionsModal.show = true
      },
      /**
       * Hide actions modal
       * @param state
       */
      hideActionsModal: (state) => {
        state.actionsModal.show = false
        delete state.actionsModal.actions
      },
      /**
       * Show status bar
       * @param state
       * @param attrs
       */
      showStatusBar: (
        state,
        {
          message,
          type = 'default',
          action = null,
          route = null,
          fixed = true,
          closeCallback = null,
        }: StatusBarMessage
      ) => {
        state.statusBar.height = 30
        state.statusBar.message = message
        state.statusBar.type = type
        state.statusBar.route = route
        state.statusBar.fixed = fixed
        state.statusBar.action = action
        state.statusBar.closeCallback = closeCallback
      },
      /**
       * Hide status bar
       * @param state
       */
      hideStatusBar: (state) => {
        state.statusBar = {
          height: 0,
          message: '',
          type: 'default',
          route: null,
          action: null,
          fixed: true,
          closeCallback: null,
        }
      },
      /**
       * Set a given color theme
       * @param state
       * @param theme
       */
      setColorTheme: (state: UXState, theme: ColorTheme) => {
        state.colorTheme = theme
        switch (theme) {
          case 'light':
            state.styles = lightStyles
            break
          case 'dark':
            state.styles = darkStyles
            break
        }
      },
      /**
       * Push a new notification
       * @param state
       * @param notification
       */
      addNotification(state: UXState, notification: Notification) {
        state.notifications.splice(state.notifications.length, 0, notification)
      },
      removeNotification(state: UXState, id: string) {
        const notificationIndex = state.notifications.findIndex(
          (n) => n.id === id
        )
        if (notificationIndex > -1) {
          state.notifications.splice(notificationIndex, 1)
        }
      },
      /**
       * Init timer is running
       * @param state
       * @param timerIsRunning
       */
      setTimerIsRunning(state: UXState, timerIsRunning) {
        state.timerIsRunning = timerIsRunning
      },
      /**
       * Update with fresh dayjs instance
       * @param state
       */
      updateTime(state: UXState) {
        state.now = new Date()
      },
      /**
       * Toggle state of sidebar
       * @param state
       * @param hidden
       */
      toggleSidebar(state: UXState, hidden?: boolean) {
        if (typeof hidden === 'boolean') {
          state.sidebar.hidden = hidden
        } else {
          state.sidebar.hidden = !state.sidebar.hidden
        }
      },
      /**
       * Set overlay mode of sidebar
       * @param state
       * @param overlay
       */
      overlaySidebar(state: UXState, overlay = true) {
        state.sidebar.overlay = overlay
      },
      /**
       * Updates the breakpoint for window size
       *
       * @param state
       */
      updateWindow(state: UXState) {
        if (!window) {
          return
        }
        const windowWidth = window.innerWidth
        let windowSize = ''
        /**
         * xs: 0,
         sm: 576px,
         md: 768px,
         lg: 992px,
         xl: 1200px
         */
        if (windowWidth <= 576) {
          windowSize = 'xs'
        } else if (windowWidth <= 768) {
          windowSize = 'sm'
        } else if (windowWidth <= 992) {
          windowSize = 'md'
        } else if (windowWidth <= 1200) {
          windowSize = 'lg'
        } else {
          windowSize = 'xl'
        }

        state.windowSize = windowSize
      },
      /**
       * Initialize timezone
       * must be executed on client side
       *
       * @param state
       */
      initTimezone(state: UXState) {
        state.timezone = dayjs.tz.guess()
        state.timeZoneOffset = Date ? new Date().getTimezoneOffset() : null
      },
      /**
       * Accept cookies
       * @param state
       * @param settings
       */
      acceptCookies(state: UXState, settings: CookieSettings) {
        state.cookieSettings = settings
      },
      /**
       * Push modal to stack
       * @param state
       * @param modal
       */
      pushModal(state: UXState, modal: Modal) {
        // remove modal at old index (if available)
        const index = state.modals.findIndex((m) => m.id === modal.id)
        if (index > -1) {
          state.modals.splice(index, 1)
        }
        // insert modal at new index
        state.modals.splice(state.modals.length, 0, modal)
      },
      /**
       * Remove modal from stack
       * @param state
       * @param modalId
       */
      removeModal(state: UXState, modalId: string) {
        const index = state.modals.findIndex((modal) => modal.id === modalId)
        if (index > -1) {
          state.modals.splice(index, 1)
        }
      },
      customCssVariables(state: UXState, variables: Record<string, string>) {
        state.customCssVariables = variables
      },
      ...options.mutations,
    },
    // actions
    actions: {
      /**
       * Toggle different color themes
       * @param state
       * @param commit
       * @param dispatch
       */
      toggleColorTheme: ({ state, commit }) => {
        if (state.colorTheme === 'light') {
          commit('setColorTheme', 'dark')
        } else {
          commit('setColorTheme', 'light')
        }
      },
      /**
       * Push a notification and setTimeout
       * @param state
       * @param commit
       * @param notification
       */
      pushNotification({ state, commit }, notification: Notification) {
        if (!Object.prototype.hasOwnProperty.call(notification, 'id')) {
          // generate random id if needed
          notification.id =
            Math.random()
              .toString(36)
              .replace(/[^a-z]+/g, '')
              .substr(0, 5) +
            Math.random()
              .toString(36)
              .replace(/[^a-z]+/g, '')
              .substr(0, 5)
        } else {
          // check if notification exists
          const notificationIndex = state.notifications.findIndex(
            (n) => n.id === notification.id
          )
          if (notificationIndex > -1) {
            commit('removeNotification', notification.id)
          }
        }
        if (!notification.persistent) {
          if (!notification.timeout) notification.timeout = 10000
        }
        commit('addNotification', notification)
      },
      /**
       * Initialize color theme according to darkMode settings on device
       * listen to changes and update theme
       * @param dispatch
       * @param state
       */
      listenToPreferredColorTheme({ state, commit }) {
        if (!state.detectColorTheme) return
        // listen to preferred color scheme
        if (window && 'matchMedia' in window) {
          const mediaQueryDark = window.matchMedia(
            '(prefers-color-scheme: dark)'
          )
          const callback = (mediaQueryDarkEvent: any) => {
            if (mediaQueryDarkEvent.matches) {
              commit('setColorTheme', 'dark')
            } else {
              commit('setColorTheme', 'light')
            }
          }
          if ('addEventListener' in mediaQueryDark) {
            mediaQueryDark.addEventListener('change', callback)
          } else if ('addListener' in mediaQueryDark) {
            // older browsers
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            mediaQueryDark.addListener(callback)
          }
          callback(mediaQueryDark)
        }
      },
      /**
       * Start timer for reactive dates
       */
      startReactiveTime({ state, commit }) {
        if (!state.timerIsRunning && window) {
          commit('setTimerIsRunning', true)
          window.setInterval(() => {
            commit('updateTime')
          }, 1000)
        }
      },
      /**
       * Close upper modal on escape keypress
       * @param state
       */
      initModalEscapeListener({ state }) {
        if (document) {
          document.addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
              if (state.modals.length > 0) {
                const upperModal = state.modals[state.modals.length - 1]
                upperModal.close()
              }
            }
          })
        }
      },
      ...options.actions,
    },
  }
  return uxStore
}
