<template>
  <div class="eb-error-container p-3 d-flex align-items-center">
    <div class="flex-fill mt-5 text-center">
      <!-- Icon -->
      <h1 class="eb-primary-color">
        <fa
          :icon="
            icons.hasOwnProperty(error.statusCode)
              ? icons[error.statusCode]
              : ['fad', 'exclamation-circle']
          "
        />
      </h1>
      <div class="eb-panel mt-3">
        <div class="body">
          <!-- Title -->
          <h1>
            {{ error.title || $t(`common.errors.${error.statusCode}.title`) }}
          </h1>
          <!-- Message -->
          <p>
            <!-- if no message is provides, nuxt stringifies the error object -> message would contain \{ -->
            {{
              String(error.message).includes('\{')
                ? $t(`common.errors.${error.statusCode}.message`)
                : error.message
            }}
          </p>
          <!-- Support -->
          <p class="eb-text-tertiary">
            {{ $t('common.errors.emailSupportTeam') }}<br />
            <a href="mailto:support@anny.co?subject=Support">support@anny.co</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Error',
  props: {
    error: {
      type: Object,
      default: () => ({}), // { statusCode, message, title }
    },
  },
  data() {
    return {
      icons: {
        404: ['fad', 'search'],
        403: ['fad', 'lock'],
        502: ['fad', 'mug-hot'],
      },
    }
  },
}
</script>

<style lang="scss">
.eb-error-container {
  width: 100%;
  max-width: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
</style>
