import Echo from 'laravel-echo'
import { Plugin } from '@nuxt/types'
import Pusher from 'pusher-js'
import { Channel, PresenceChannel } from 'laravel-echo/dist/channel'
import { Store } from 'vuex'
import { AuthService } from '@/shared/services/AuthService'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { PusherChannel } from 'laravel-echo/dist/channel'

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $echo: EchoService
  }
  interface Context {
    $echo: EchoService
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $echo: EchoService
  }
}

/**
 * Add wrapper for echo to prevent auto connection and add error handling.
 */
export class EchoService {
  protected options: Record<string, any> | null = null
  protected $echo: Echo | null = null
  protected $guestEcho: Echo | null = null
  protected $store: Store<any>
  protected authService: AuthService
  protected logger: (...args: any[]) => void

  constructor(
    options: Record<string, any> | null,
    store: Store<any>,
    authService: AuthService,
    logger: (...args: any[]) => void
  ) {
    this.options = options
    this.$store = store
    this.authService = authService
    this.logger = logger
  }

  get echo() {
    return this.$echo
  }

  /**
   * Initialize echo instance only when needed.
   * "When you create a new Pusher object you are automatically connected to Channels"
   * @docs https://pusher.com/docs/channels/using_channels/connection/#connecting-to-channels
   * @param refresh
   * @private
   */
  private initEcho(refresh = false) {
    if (refresh) {
      this.$echo?.disconnect()
      this.$echo = null
    }
    if (!this.$echo) {
      this.$echo = new Echo({
        ...this.options,
        client: new Pusher(this.options!.key, this.options!),
      })
    }
  }

  private initGuestEcho(secretToken: string) {
    const guestOptions = {
      ...this.options,
      authEndpoint: this.options!.authEndpoint + '-guest',
      auth: {
        headers: {
          'X-Broadcast-Token': secretToken,
          accept: 'application/json',
        },
      },
    }

    if (this.$guestEcho) {
      this.$guestEcho.disconnect()
      this.$guestEcho = null
    }

    if (!this.$guestEcho) {
      this.$guestEcho = new Echo({
        ...guestOptions,
        client: new Pusher(this.options!.key, guestOptions),
      })
    }
  }

  public channelIsSubscribed(channelId: string) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return !!(this.$echo?.channel(channelId) as PusherChannel).subscription
      ?.subscribed
  }

  channel(channelId: string, tries = 0): Channel {
    this.initEcho()
    const channel = this.$echo!.channel(channelId)
    channel.error(
      async (error: { error: string; status?: number; type?: string }) => {
        this.logger('channel error', error)
        if (error.status === 401 && tries < 3) {
          // retry authentication
          this.leave(channelId)
          try {
            await this.refreshToken()
            // retry
            this.channel(channelId, tries + 1)
          } catch (e) {
            console.log(e)
          }
        }
      }
    )
    return channel
  }

  connect() {
    this.initEcho()
    this.$echo!.connect()
  }

  disconnect() {
    if (!this.$echo) {
      return
    }
    // this.initEcho()
    this.$echo!.disconnect()
    this.$echo = null
  }

  join(channelId: string, tries = 0): PresenceChannel {
    this.initEcho()
    const channel = this.$echo!.join(channelId)
    channel.error(
      async (error: { error: string; status?: number; type?: string }) => {
        this.logger('join error', error)
        if (error.status === 401 && tries < 3) {
          // retry authentication
          this.leave(channelId)
          try {
            await this.refreshToken()
            // retry
            this.join(channelId, tries + 1)
          } catch (e) {
            console.log(e)
          }
        }
      }
    )
    return channel
  }

  leave(channel: string) {
    this.initEcho()
    this.$echo!.leave(channel)
  }

  leaveChannel(channel: string) {
    this.initEcho()
    this.$echo!.leaveChannel(channel)
  }

  private(channelId: string, tries = 0): Channel {
    this.initEcho()
    const channel = this.$echo!.private(channelId)
    channel.error(
      async (error: { error: string; status?: number; type?: string }) => {
        this.logger('private error', error)
        if (error.status === 401 && tries < 3) {
          // retry authentication
          this.leave(channelId)
          try {
            await this.refreshToken()
            // retry
            this.private(channelId, tries + 1)
          } catch (e) {
            console.log(e)
          }
        }
      }
    )
    return channel
  }

  guestChannel(channelId: string, secretToken: string) {
    this.initGuestEcho(secretToken)
    return this.$guestEcho!.private(channelId)
  }

  leaveGuestChannel(channel: string) {
    this.$guestEcho!.leaveChannel(channel)
  }

  private async refreshToken() {
    await this.authService.refreshAccessToken()
  }

  /**
   * Update echo instance with new access token.
   * @param accessToken
   * @param refresh
   */
  updateAccessToken(accessToken: string, refresh = false) {
    this.options = {
      ...this.options,
      auth: {
        ...(this.options!.auth ?? {}),
        headers: {
          ...(this.options!.auth?.headers ?? {}),
          authorization: `Bearer ${accessToken}`,
        },
      },
    }
    if (refresh) {
      this.initEcho(true)
    } else if (this.$echo) {
      // update auth options
      this.$echo.connector.pusher.config.auth.headers[
        'Authorization'
      ] = `Bearer ${accessToken}`
      this.$echo.options.auth.headers['Authorization'] = `Bearer ${accessToken}`
    }
  }
}

const echoPlugin: Plugin = (context, inject) => {
  let logger = () => {
    // null logger
  }

  if ('$logger' in context) {
    logger = context.$logger
  }

  const echoService = new EchoService(
    {
      broadcaster: 'pusher',
      auth: {
        headers: {
          accept: 'application/json',
        },
      },
      key: context.$config.pusherAppKey,
      wsHost: context.$config.pusherHost,
      wsPort: context.$config.pusherPort,
      wssPort: context.$config.pusherPort,
      authEndpoint: context.$config.pusherAuthEndpoint,
      forceTLS: false,
      encrypted: true,
      disableStats: true,
      enabledTransports: ['ws', 'wss'],
    },
    context.store,
    context.$authService,
    logger
  )

  function provideAccessTokenForEcho() {
    const accessToken: string | null = context.$authService.accessToken
    if (accessToken) {
      echoService.updateAccessToken(accessToken)
    }
  }

  provideAccessTokenForEcho()

  inject('echo', echoService)

  // use auth service mitt to subscribe to token refresh event
  context.$authService.mitt.on('TokenRefreshed', () => {
    provideAccessTokenForEcho()
  })
}

export default echoPlugin
