import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { Attr, BelongsTo } from '@anny.co/vue-jsonapi-orm'
import { User } from '@/shared/jsonapi-orm/bookingbuddy/User'
import { Queue } from '@/shared/jsonapi-orm/queues/Queue'
import { QueueCounter } from '@/shared/jsonapi-orm/queues/QueueCounter'

export class QueueAgent extends ApiResource {
  static jsonApiType = 'queue-agents'

  @Attr(true) active: boolean
  @Attr() createdAt: string
  @Attr() updatedAt: string

  @BelongsTo() user?: User
  @BelongsTo() queue?: Queue
  @BelongsTo() counter?: QueueCounter
}
