import { Context, Plugin } from '@nuxt/types'
import { LinkService } from '@/shared/utils/bookings/links'

declare module 'vue/types/vue' {
  interface Vue {
    $linkService: LinkService
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$customerAccount inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $linkService: LinkService
  }
  // nuxtContext.$customerAccount
  interface Context {
    $linkService: LinkService
  }
}

const plugin: Plugin = function (ctx: Context, inject) {
  const linkService = new LinkService(
    ctx.$config.apiBaseUrl,
    ctx.$config.shopBaseUrl
  )
  inject('linkService', linkService)
}
export default plugin
