import { Attr, Meta } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'

export enum SexType {
  UNKNOWN = 0,
  MALE = 1,
  FEMALE = 2,
  OTHER = 9,
}

export class User extends ApiResource {
  static jsonApiType = 'users'
  static convertSnakeCase = false

  @Attr() name: string
  @Attr() title: string
  @Attr(SexType.UNKNOWN) sex?: SexType
  @Attr(null) birthDate?: string | null
  @Attr() givenName: string
  @Attr() familyName: string
  @Attr() email: string
  @Attr() emailVerifiedAt?: string
  @Attr() company?: string
  @Attr() mobile?: string
  @Attr() mobileVerifiedAt?: string
  @Attr() phone?: string
  @Attr() timezone?: string
  @Attr() isApp?: boolean
  @Attr() isManaged?: boolean
  @Attr('de') locale: string
  @Attr() createdAt: string

  @Meta() needsEmailVerification?: boolean
  @Meta() updatedEmail?: string | null

  async validateEmail(): Promise<boolean> {
    if (!this.persisted) return true
    try {
      const response = await this.api()
        .query({ email: this.email })
        .request('validate-unique')
      return response.data.is_available as boolean
    } catch (e) {
      console.log(e)
      return false
    }
  }

  async validateMobile(): Promise<boolean> {
    if (!this.persisted) return true
    try {
      const response = await this.api()
        .query({ mobile: this.mobile })
        .request('validate-unique')
      return response.data.is_available as boolean
    } catch (e) {
      console.log(e)
      return false
    }
  }

  /**
   * Resend email for verification
   */
  async resendVerificationNotification(): Promise<boolean> {
    try {
      this.$isLoading = true
      await this.api().request('resend-verification-email')
      return true
    } catch (e) {
      console.log(e)
      return false
    } finally {
      this.$isLoading = false
    }
  }

  /**
   * Initialize the deletion
   * user needs to confirm deletion via email
   */
  async requestDeletion() {
    return await this.api().request('request-deletion')
  }
}

export interface UserInfo {
  uuid: string
  name: string
  givenName: string
  familyName: string
  email: string
  emailVerifiedAt: string | null
  mobile: string | null
  mobileVerifiedAt: string | null
  timezone: string
  locale: string
  isAdmin: boolean
  platformId: string | null
  rawAttributes: {
    idp_id: string
    idp_name: string
    attributes: Record<string, any>
  }[]
  mappedAttributes: {
    idp_id: string
    idp_name: string
    attributes: Record<string, any>
  }[]
}
