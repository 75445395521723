import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { Attr, HasMany } from '@anny.co/vue-jsonapi-orm'
import { Queue } from '@/shared/jsonapi-orm/queues/Queue'
import { QueueTicket } from '@/shared/jsonapi-orm/queues/QueueTicket'

export class QueueCounter extends ApiResource {
  static jsonApiType = 'queue-counters'

  @Attr() name: string
  @Attr() abbreviation: string
  @Attr(100) orderIndex: string
  @Attr() createdAt: string
  @Attr() updatedAt: string

  @HasMany() queues?: Queue[]
  @HasMany() tickets?: QueueTicket[]
}
