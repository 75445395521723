export function base64_encode(value: string) {
  // Check if btoa exists
  if (typeof btoa !== 'undefined') {
    return btoa(value)
  }

  // Check if we have a buffer -> Node
  if (typeof Buffer !== 'undefined') {
    return Buffer.from(value, 'binary').toString('base64')
  }

  throw new Error('Could not base64 encode value.')
}

export function base64_decode(value: string) {
  // Check if btoa exists
  if (typeof atob !== 'undefined') {
    return atob(value)
  }

  // Check if we have a buffer -> Node
  if (typeof Buffer !== 'undefined') {
    return Buffer.from(value, 'base64').toString('binary')
  }

  throw new Error('Could not decode base64 value.')
}
